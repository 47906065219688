import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Search from '../formComponent/Search';
import { dashboard } from '../action/actions';
import { UseAuth } from '../../auth/AuthContext';

function BreadCrumbsV2({ children, searchHandler, heading }) {
	const { user } = useContext(UseAuth);
	return (
		<div className="breadcrumb-wrapper breadcrumb-contacts">
			<div>
				<h1>{heading} </h1>
				<p className="breadcrumbs">
					<span>
						<Link to={dashboard(user?.role_as)}>Home</Link>
					</span>
					<span>
						<i className="mdi mdi-chevron-right"></i>
					</span>
					{heading}
				</p>
			</div>
			{searchHandler && (
				<div className="d-flex" style={{ gap: '5px' }}>
					<Search searchHandler={searchHandler} />
				</div>
			)}

			{children}
		</div>
	);
}

export default BreadCrumbsV2;
