export const apisAdmin = {
	// category admin
	advertise_utility: {
		getConversionLocationById: '/admin/get/conversion/location/',
	},
	category: {
		get: '/view-category',
		post: '/store-category',
		edit: '/update-category/',
		delete: '/delete-category/',
		statusChange: '/category-status/',
	},
	subCategory: {
		get: '/view-subcategory',
		post: '/store-subcategory',
		edit: '/update-subcategory/',
		delete: '/delete-subcategory/',
		getById: '/edit-subcategory/',
		statusChange: '/subcategory-status/',
	},
	serviceCategory: {
		get: '/admin/servicecategory',
		post: '/admin/servicecategory/store',
		edit: '/admin/servicecategory/update/',
		delete: '/admin/servicecategory/delete/',
		getById: '',
		statusChange: '/admin/servicecategory/status/',
	},
	serviceSubCategory: {
		get: '/admin/service-sub-category',
		post: '/admin/service-sub-category',
		edit: '/admin/service-sub-category/',
		delete: '/admin/service-sub-category/',
		getById: '/admin/service-sub-category/',
		statusChange: '',
	},

	supportCategory: {
		get: '/admin/supportboxcategory',
		post: '/admin/supportboxcategory',
		edit: '/admin/supportboxcategory/',
		delete: '/admin/supportboxcategory/',
		getById: '/admin/supportboxcategory/',
		statusChange: '',
	},
	supportSubCategory: {
		get: '/admin/supportproblem-topic',
		post: '/admin/supportproblem-topic',
		edit: '/admin/supportproblem-topic/',
		delete: '/admin/supportproblem-topic/',
		getById: '/admin/supportproblem-topic/',
		statusChange: '',
	},

	orders: {
		ready: '/admin/order-ready',
		processing: '/admin/product-processing',
		return: '/admin/order-return',
	},
	user: {
		addBalance: '/admin/add-user-balance/',
		removeBalance: '/admin/remove-user-balance/',
		editBalance: '/admin/edit-user-balance/',
	},
	support: {
		get: `/admin/supportbox`,
	},
	coupon: {
		get: `/admin/coupons`,
	},
	withdraw: {
		get: `/admin/all-withdraw`,
	},

	product: {
		get: '/',
		getById: '/edit-product/',
	},

	note: {
		get: '/admin/note/index',
		post: '/admin/note/store',
		getById: '/admin/note/vendor/',
	},
	payments: { getById: '/admin/vendor/payment/history/' },
	serviceOrder: { getById: '/admin/vendor/service/order/' },
	advertisement: { getById: '/admin/vendor/advertise/' },

	// setting
	setting: {
		get: '/admin/settings',
		edit: '/admin/settings-update/1',
	},
	deliveryCompany: {
		get: '/admin/deliver-company/',
	},

	// home content
	homeContentService: {
		get: '/admin/service',
		post: '/admin/service',
		edit: '/admin/service/',
		delete: '/admin/service/',
		getById: '/admin/service/',
		statusChange: '',
	},
	homeContentOrganization: {
		get: '/admin/organization',
		post: '/admin/organization',
		edit: '/admin/organization/',
		delete: '/admin/organization/',
		getById: '/admin/organization/',
		statusChange: '',
	},
	homeContentItService: {
		get: '/admin/it-service',
		post: '/admin/it-service',
		edit: '/admin/it-service/',
		delete: '/admin/it-service/',
		getById: '/admin/it-service/',
		statusChange: '',
	},
	homeContentOrganizationTwo: {
		get: '/admin/organizationTwo',
		post: '/admin/organizationTwo',
		edit: '/admin/organizationTwo/',
		delete: '/admin/organizationTwo/',
		getById: '/admin/organizationTwo/',
		statusChange: '',
	},
	homeContentPartner: {
		get: '/admin/partner',
		post: '/admin/partner',
		edit: '/admin/partner/',
		delete: '/admin/partner/',
		getById: '/admin/partner/',
		statusChange: '',
	},
	homeContentFooterMedia: {
		get: '/admin/footer-media',
		post: '/admin/footer-media',
		edit: '/admin/footer-media/',
		delete: '/admin/footer-media/',
		getById: '/admin/footer-media/',
		statusChange: '',
	},
	homeContentContactPageData: {
		get: '/admin/contact-page-data',
		post: '/admin/contact-page-data',
		edit: '/admin/contact-page-data/',
		delete: '/admin/contact-page-data/',
		getById: '/admin/contact-page-data/',
		statusChange: '',
	},

	// about content
	aboutContentCompanion: {
		get: '/admin/companion',
		post: '/admin/companion',
		edit: '/admin/companion/',
		delete: '/admin/companion/',
		getById: '/admin/companion/',
		statusChange: '',
	},
	aboutContentMission: {
		get: '/admin/mission',
		post: '/admin/mission',
		edit: '/admin/mission/',
		delete: '/admin/mission/',
		getById: '/admin/mission/',
		statusChange: '',
	},
	aboutContentTestimonial: {
		get: '/admin/testimonial',
		post: '/admin/testimonial',
		edit: '/admin/testimonial/',
		delete: '/admin/testimonial/',
		getById: '/admin/testimonial/',
		statusChange: '',
	},
	aboutContentMember: {
		get: '/admin/member',
		post: '/admin/member',
		edit: '/admin/member/',
		delete: '/admin/member/',
		getById: '/admin/member/',
		statusChange: '',
	},
	advertiserContentFaq: {
		get: '/admin/faq',
		post: '/admin/faq',
		edit: '/admin/faq/',
		delete: '/admin/faq/',
		getById: '/admin/faq/',
		statusChange: '',
	},
	brand: {
		get: '/view-brand',
		post: '/store-brand',
		edit: '/update-brand/',
		delete: '/delete-brand/',
		statusChange: '/category-status/',
	},
	notification: {
		get: '/admin/notification',
		read: '/admin/notification/mark-as-read/',
		readAll: '/admin/notification/mark-as-read-all',
	},

	test: {
		get: '/',
		post: '/',
		edit: '/',
		delete: '',
		getById: '/',
	},
};
export const apisVendor = {
	// category
	category: {
		get: '/vendor-all-category',
	},
	//  unit is color/size etc
	unit: {
		get: '/vendor/unit',
		post: '/vendor/unit/store',
		edit: '/vendor/unit/edit/',
		update: '/vendor/unit/update/',
		delete: '/vendor/unit/delete/',
		statusChange: '/vendor/unit/status/',
	},

	unitColor: {
		get: '/view-color',
		post: '/store-color',
		edit: '/edit-color/',
		update: '/update-color/',
		delete: '/delete-color/',
		statusChange: '',
	},
	variation: {
		get: '/view-size',
		post: '/store-size',
		edit: '/edit-size/',
		update: '/update-size/',
		delete: '/delete-size/',
		statusChange: '',
	},

	paymentMethods: {
		get: '/vendor/payment-method',
		post: '/vendor/payment-method/store',
		edit: '/vendor/payment-method/edit/',
		update: '/vendor/payment-method/update/',
		delete: '/vendor/payment-method/delete/',
		statusChange: '/vendor/payment-method/status/',
	},

	// sub-unit is from utility like red/xl etc
	subUnit: {
		get: '/vendor/sub-units',
		post: '/vendor/sub-unit/store',
		edit: '/vendor/sub-unit/update/',
		delete: '/vendor/sub-unit/delete/',
		getById: '',
	},
	warehouse: {
		get: '/vendor/warehouse',
		post: '/vendor/warehouse/store',
		edit: '/vendor/warehouse/update/',
		delete: '/vendor/warehouse/delete/',
		getById: '/vendor/warehouse/edit/',
		statusChange: '/vendor/warehouse/status/',
	},
	deliveryCharge: {
		get: '/vendor/delivery-charge',
		post: '/vendor/delivery-charge/store',
		edit: '/vendor/delivery-charge/update/',
		delete: '/vendor/delivery-charge/delete/',
		getById: '/vendor/delivery-charge/edit/',
		statusChange: '/vendor/delivery-charge/status/',
	},
	deliveryArea: {
		get: '/vendor/delivery-and-pickup-address',
		post: '/vendor/delivery-and-pickup-address/store',
		edit: '/vendor/delivery-and-pickup-address/update/',
		delete: '/vendor/delivery-and-pickup-address/delete/',
		getById: '/vendor/delivery-and-pickup-address/edit/',
		statusChange: '/vendor/delivery-and-pickup-address/status/',
	},

	supplier: {
		get: '/vendor/supplier',
		post: '/vendor/supplier/store',
		edit: '/vendor/supplier/update/',
		delete: '/vendor/supplier/delete/',
		getById: '/vendor/supplier/edit/',
		statusChange: '/vendor/supplier/status/',
	},
	customer: {
		get: '/vendor/customer',
		post: '/vendor/customer/store',
		edit: '/vendor/customer/update/',
		delete: '/vendor/customer/delete/',
		getById: '/vendor/customer/edit/',
		statusChange: '/vendor/customer/status/',
	},
	product: {
		getHelperUtility: '/vendor-product-create',
		get: '/vendor/product',
		post: '/vendor-store-product',
		edit: '/vendor-update-product/',
		damage: '/vendor/product-damage',
		damageStore: '/vendor/product-damage/store',
		// delete: '/vendor/customer/delete/',
		getById: '/vendor-edit-product/',
		// statusChange: '/vendor/customer/status/',
	},
	purchase: {
		getHelperUtility: '/vendor/product-purchase/create',
		getSupplierProduct: '/vendor/product-purchase/supplier/product/',
		post: '/vendor/product-purchase/store',
		get: '/vendor/product-purchase',
		invoice: '/vendor/product-purchase/show/',
		statusChange: '/vendor/product-purchase/status/',
		addPayment: '/vendor/product-purchase/add/payment/',
		return: '/vendor/product-purchase/return/',
		returnList: '/vendor/product-purchase/return/list',
		returnView: '/vendor/product-purchase/return/list/details/',
		history: '/vendor/product-purchase/supplier/payment/history',
	},
	sales: {
		getHelperUtility: '/vendor/product-pos-sales/create',
		productById: '/vendor/product-pos-sales/product/select/',
		post: '/vendor/product-pos-sales/store',
		get: '/vendor/product-pos-sales/manage',
		invoice: '/vendor/product-pos-sales/show/',
		addPayment: '/vendor/product-pos-sales/add/payment/',
		history: '/vendor/product-pos-sales/customer/payment/history',
		return: '/vendor/product-pos-sales/return/list',
		returnInvoice: '/vendor/product-pos-sales/return/list/details/',
		returnStore: '/vendor/product-pos-sales/return/',
		orderExchange: 'vendor/product-pos-sales/exchange/',
		scan: '/vendor/product-pos-sales/scan',
		barcodeGenerate: '/vendor/barcode/generate',
		manageBarcode: '/vendor/barcode/manage',
		barcodeRegenerate: '/vendor/barcode/re-generate',
	},
	orderSource: {
		get: '/vendor/pos-order-resource',
		post: '/vendor/pos-order-resource/store',
		edit: '/vendor/pos-order-resource/update/',
		delete: '/vendor/pos-order-resource/delete/',
		getById: '/vendor/pos-order-resource/edit/',
		statusChange: '/vendor/pos-order-resource/status/',
	},
	deliveryCompany: {
		get: '/vendor/delivery-company',
		activeAll: '/vendor/delivery-company/list',
		post: '/vendor/delivery-company/store',
		edit: '/vendor/delivery-company/update/',
		delete: '/vendor/delivery-company/delete/',
		getById: '/vendor/delivery-company/edit/',
		statusChange: '/vendor/delivery-company/status/',
	},
	wastage: {
		get: '/vendor/product-pos-sales/wastage/return/list',
		post: '/vendor/product-pos-sales/wastage/return',
		getById: '/vendor/product-pos-sales/wastage/return/get/invoice?invoice_no=',
		return: '/vendor/product-pos-sales/wastage/return',
	},
	orderManual: {
		getHelperUtility: '/vendor/product-manual-order/create',
		customerById: '/vendor/product-manual-order/customer/select/',
		post: '/vendor/product-manual-order/order/store',
		invoice: '/vendor/product-manual-order/invoice-show/',
	},
	employee: {
		get: '/vendor/employee/manage',
		permissions: '/vendor/employee/permissions',
		post: '/vendor/employee/store',
		edit: '/vendor/employee/update/',
		delete: '/vendor/employee/delete/',
		getById: '/vendor/employee/show/',
		roleCreate: '/vendor/role-permission/store',
		roleById: '/vendor/role-permission/show/',
		roleManage: '/vendor/role-permission/manage',
		roleUpdate: '/vendor/role-permission/update/',
		roleDelete: '/vendor/role-permission/delete/',
	},
	vendorInfo: {
		get: '/vendor/info-settings',
		edit: '/vendor/info-settings/update',
	},
	report: {
		stock: '/vendor/report/stock-report',
		sales: '/vendor/report/sales-report',
		dueSales: '/vendor/report/due-sales-report',
		purchase: '/vendor/report/purchase-report',
		warehouse: '/vendor/report/warehouse-report',

		stockShortage: '/vendor/report/stock-shortage-report',
		topRepeatCustomer: '/vendor/report/top-repeat-customer',
		salesReportProductId: '/vendor/report/sales-report-product-id',
		salesReportDaily: 'vendor/report/sales-report-daily-product-wise',
	},
	notification: {
		get: '/vendor/notification',
		read: '/vendor/notification/mark-as-read/',
		readAll: '/vendor/notification/mark-as-read-all',
	},
	orders: {
		ready: '/vendor/order-ready',
		processing: '/vendor/product-processing',
		return: '/vendor/order-return',
	},
	note: {
		get: '/vendor/my-note',
		statusChange: '/vendor/my-note',
	},
	test: {
		get: '/',
		post: '/',
		edit: '/',
		delete: '',
		getById: '/',
	},
};

export const apisAffiliate = {
	notification: {
		get: '/affiliator/notification',
		read: '/affiliator/notification/mark-as-read/',
		readAll: '/affiliator/notification/mark-as-read-all',
	},
	orders: {
		ready: '/affiliator/order-ready',
		processing: '/affiliator/product-processing',
		return: '/affiliator/order-return',
	},
	product: {
		getAll: '/affiliator/products',
	},
};

export const apisChat = {
	lists: '/conversation',
	send: '/messages/send', // message receiver_id
	getById: '/messages/',
};

export const apisCommon = {
	settings: {
		get: '/settings',
	},
	category: {
		get: '/get-category',
	},
};
