import React, { useContext, useEffect, useReducer, useRef } from 'react';
import {
	ChatContext,
	ChatUserList,
	CheckMembership,
	MessageSubmit,
	Messages,
} from './Chat';
import { FaXmark } from 'react-icons/fa6';
import Aos from 'aos';
import { initialState, reducer, setRoot } from './action';
import { apisChat, useApi, useApiForId, useInterval } from '../../api/common';
import { UseAuth } from '../../auth/AuthContext';

function FloatChat({ setChatOpen }) {
	const [state, dispatch] = useReducer(reducer, initialState);
	const { user } = useContext(UseAuth);

	const chatContainerRef = useRef(null);

	const {
		response: chatLists,

		refetch,
	} = useApi({
		// endPoint: apisChat.getById + '2',
		endPoint: apisChat.lists,
	});

	useEffect(() => {
		if (chatLists?.conversations?.length > 0) {
			setRoot(dispatch, chatLists?.conversations?.[0], 'user');
			setId(chatLists?.conversations?.[0]?.id);
		}
	}, [chatLists?.conversations]);

	const {
		response: chatMessage,
		refetch: refetchMessage,
		setId,
	} = useApiForId({
		endPoint: apisChat.getById,
	});

	useEffect(() => {
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTop =
				chatContainerRef.current.scrollHeight;
		}
	}, [chatMessage, state.user.id]);

	useInterval(() => {
		refetchMessage();
	}, 10000);

	useInterval(() => {
		refetch();
	}, 500000);
	useEffect(() => {
		Aos.init({ delay: 100, offset: 50, duration: 300 });
	}, []);
	return (
		<ChatContext.Provider value={{ state, dispatch }}>
			<div data-aos={`fade-up`} className="chat__float_box">
				<div className="header_chat">
					<h1 className="header_chat_tex">Message</h1>
					<button
						onClick={() => setChatOpen(false)}
						className="header_chat_btn"
					>
						<FaXmark />
					</button>
				</div>
				<CheckMembership user={user} access="chat_access" value="yes" />

				{user?.usersubscription?.chat_access === 'yes' && (
					<div className="chart__wrap_float">
						<div className="chat__user-list">
							<ul>
								{chatLists?.conversations?.map((e) => (
									<ChatUserList key={e?.id} data={e} setId={setId} />
								))}
							</ul>
						</div>
						<div className="chat__user-message">
							<div>
								<ChatUserList data={state?.user} />

								<div
									style={{ height: '400px', overflow: 'auto' }}
									ref={chatContainerRef}
								>
									{chatMessage?.messages?.map((e) => (
										<ul className="chat__user-message__box">
											{e?.sender_id === state.user.id && <Messages data={e} />}
											{e?.sender_id !== state.user.id && (
												<Messages from="admin" data={e} />
											)}
										</ul>
									))}
								</div>
							</div>
							<MessageSubmit refetch={refetchMessage} />
						</div>
					</div>
				)}
			</div>
		</ChatContext.Provider>
	);
}

export default FloatChat;
