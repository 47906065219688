import React, {
	createContext,
	useContext,
	useEffect,
	useReducer,
	useRef,
	useState,
} from 'react';
import BreadCrumbsV2 from '../breadcrumbs/BreadCrumbsV2';
import { Helmet } from 'react-helmet';
import Aos from 'aos';
import { img } from '../../assets/sos/image';
import { Link } from 'react-router-dom';
import {
	apisChat,
	useApi,
	useApiForId,
	useInterval,
	usePost,
} from '../../api/common';
import { handleInput, initialState, reducer, setRoot } from './action';
import { dashboard, timeToCovertCurrentTime } from '../action/actions';
import { IMG_PATH } from '../env';
import { NoItemFount } from '../table/TableComponents';
import { UseAuth } from '../../auth/AuthContext';
import useQuery from '../action/useQueryString';
import SidebarLoader from '../loader/SidebarLoader';
export const ChatContext = createContext(null);

function Chat() {
	let query = useQuery();
	const id = query.get('id');
	const [searchUser, setSearchUser] = useState('');

	const [state, dispatch] = useReducer(reducer, initialState);
	const { user, isLoading: userLoading } = useContext(UseAuth);
	const chatContainerRef = useRef(null);

	const {
		response: chatLists,
		isLoading,
		refetch,
	} = useApi({
		// endPoint: apisChat.getById + '2',
		endPoint: apisChat.lists,
	});
	useEffect(() => {
		if (chatLists?.conversations?.length > 0) {
			if (id) {
				const user = chatLists?.conversations?.find((e) => e?.id == id);
				setRoot(dispatch, user, 'user');
				setId(id);
			} else {
				setRoot(dispatch, chatLists?.conversations?.[0], 'user');
				setId(chatLists?.conversations?.[0]?.id);
			}
		}
	}, [chatLists?.conversations]);

	const {
		response: chatMessage,
		isLoading: messageLoading,
		refetch: refetchMessage,
		setId,
	} = useApiForId({
		endPoint: apisChat.getById,
	});
	const filteredConversations = chatLists?.conversations?.filter(
		(conversation) =>
			conversation.name.toLowerCase().includes(searchUser.toLowerCase())
	);
	useEffect(() => {
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTop =
				chatContainerRef.current.scrollHeight;
		}
	}, [chatMessage, state?.user?.user_id]);

	useInterval(() => {
		if (chatLists?.conversations?.length >= 0) {
			refetchMessage();
		}
	}, 10000);

	useInterval(() => {
		refetch();
	}, 500000);
	// loading animation
	useEffect(() => {
		Aos.init({ delay: 300, offset: 50, duration: 300 });
	}, []);
	return (
		<ChatContext.Provider value={{ state, dispatch }}>
			<div className="ec-content-wrapper">
				<Helmet>
					<title>Chat-SOS</title>
				</Helmet>
				<div className="content">
					<BreadCrumbsV2 heading="Chat"></BreadCrumbsV2>
					{userLoading && <SidebarLoader length={1} />}
					{!userLoading && (
						<CheckMembership user={user} access="chat_access" value="yes" />
					)}
					{user?.usersubscription?.chat_access === 'yes' && (
						<div data-aos="fade" className=" ">
							<div className="chart__wrap  ">
								<div className="chat__user-list">
									<ul>
										{isLoading && <SidebarLoader length={8} />}
										{!isLoading && (
											<li className="p-2">
												<input
													type="search"
													className="form-control here slug-title"
													placeholder="Search"
													value={searchUser}
													onChange={(e) => setSearchUser(e.target.value)}
												/>
											</li>
										)}

										{filteredConversations?.map((e) => (
											<ChatUserList key={e?.id} data={e} setId={setId} />
										))}
									</ul>
								</div>
								<div className="chat__user-message">
									<div>
										<ChatUserList data={state?.user} />
										{chatMessage?.messages?.length === 0 && (
											<NoItemFount children="No Message" />
										)}
										<div
											style={{ height: '500px', overflow: 'auto' }}
											ref={chatContainerRef}
										>
											{messageLoading && <SidebarLoader length={8} />}
											{chatMessage?.messages?.map((e) => (
												<ul className="chat__user-message__box">
													{e?.sender_id === state?.user?.id && (
														<Messages data={e} />
													)}
													{e?.sender_id !== state?.user?.id && (
														<Messages from="admin" data={e} />
													)}
												</ul>
											))}
										</div>
										{/* <LoaderBox length={5} /> */}
									</div>
									<MessageSubmit refetch={refetchMessage} />
								</div>
								<ChatUserInfo />
							</div>
						</div>
					)}
				</div>
			</div>
		</ChatContext.Provider>
	);
}

export default Chat;

export const MessageSubmit = ({ refetch }) => {
	const { state, dispatch } = React.useContext(ChatContext);

	const { loading, mutate } = usePost(refetch, true);

	const handelSubmit = async (e) => {
		e.preventDefault();
		await mutate(
			{
				data: {
					receiver_id: state?.user?.id,
					message: state.data.message,
				},
				endPoint: apisChat.send,
			},
			{
				onSuccess: ({ data }) => {
					// if validation error
					if (data?.status === 400) {
						return;
					}

					// if success
					if (data?.status === 200) {
						handleInput(dispatch, '', 'message');
					}
				},
			}
		);
	};
	return (
		<div className="chat__message_submit_box">
			<div className="chat__message_submit_box_file">
				<img src={img.plusRound} alt="" />
			</div>
			<form onSubmit={handelSubmit} className="chat__message_submit_text">
				<input
					onChange={(e) => handleInput(dispatch, e.target.value, 'message')}
					value={state.data.message}
					placeholder="Write your text..."
					className="__inp"
					type="text"
				/>
				<button type="submit" disabled={loading} className="__subBtn">
					<img src={img.submitIcon} alt="" />
				</button>
			</form>
		</div>
	);
};

// Messages
export const Messages = ({ from, data }) => {
	return (
		<li>
			<div className="badge__chat_time">
				<p className="badge__chat_time_show">
					{timeToCovertCurrentTime(data?.created_at)}
					{timeToCovertCurrentTime(data?.created_at) !== 'just now' && ' ago'}
				</p>
			</div>
			<div className="chat__from_user_message">
				{from !== 'admin' && (
					<div className="chat__from_user_message__image">
						<img src={img.user} alt="" />
					</div>
				)}
				{from !== 'admin' ? (
					<p className="chat__from_user_message__text">{data?.message}</p>
				) : (
					<div className="chat__from_user_message__text__admin">
						<p className="chat__from_user_message__text__user">
							{data?.message}
						</p>
					</div>
				)}
			</div>
		</li>
	);
};
// user list item
export const ChatUserList = ({ data, setId }) => {
	const { dispatch } = React.useContext(ChatContext);

	return (
		<li
			onClick={() => {
				setRoot(dispatch, data, 'user');
				setId(data?.id);
			}}
		>
			<Link className="chat__user-list__item" href="#">
				<div className="chat__user-list__image">
					<img
						src={data?.image ? IMG_PATH + '/' + data?.image : img.user}
						alt=""
					/>
				</div>
				<div className="chat__user-list__info">
					<div>
						<p
							className="chat__user-list__info-name"
							style={{ textTransform: 'capitalize' }}
						>
							{data?.name}
						</p>
						<p className="chat__user-list__info-subTxt">
							{data?.role_as == '2' ? 'Merchant' : 'Drop Shipper'}
						</p>
					</div>

					{/* if time  */}
					{data?.updated_at && (
						<p className="chat__user-list__info-subTxt">
							{timeToCovertCurrentTime(data?.updated_at)}
						</p>
					)}
				</div>
			</Link>
		</li>
	);
};

// user info
export const ChatUserInfo = () => {
	const { state } = React.useContext(ChatContext);

	return (
		<div className="chat__user-info">
			<div className="chat__user-info-contents">
				<div className="chat__user-info__image">
					<img
						src={
							state?.user?.user?.image
								? IMG_PATH + '/' + state?.user?.image
								: img.user
						}
						alt=""
					/>
				</div>
				<p className="chat__user-list__info-name">{state?.user?.name}</p>
				<p className="chat__user-list__info-subTxt">
					{state?.user?.role_as == '2' ? 'Merchant' : 'Drop Shipper'}
				</p>
				{/* <p className="chat__user-list__info-subTxt">15min ago</p> */}
			</div>
		</div>
	);
};

export const CheckMembership = ({ user, access, value }) => {
	return (
		user?.usersubscription?.[access] !== value && (
			<div data-aos="fade" className=" ">
				<div className="alert alert-warning m-3" role="alert">
					Free Membership is not available. Please upgrade to Membership.{' '}
					<Link to={`${dashboard(user?.role_as)}/renew`}> Go to Upgrade</Link>
				</div>
			</div>
		)
	);
};
