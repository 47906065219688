export const IMG_PATH = process.env.REACT_APP_IMG_URL;
// export const IMG_PATH = 'https://dev.startownstartup.com';

// export const DOMAIN_URL = 'localhost';
export const DOMAIN_URL = process.env.REACT_APP_DOMAIN;

// export const LIVE_LINK = 'http://localhost:3000';
export const LIVE_LINK = process.env.REACT_APP_LIVE_LINK;

// export const TEST = "ok"
export const TEST = process.env.REACT_APP_TESTING;

// export const BASE_URL = 'https://dev.startownstartup.com/api';
export const BASE_URL = process.env.REACT_APP_BASE_URL;
