export const sign = {
	tk: 'Tk',
	dollar: '$',
	percent: '%',
};

export const NAME = {
	unit: {
		name: 'Unit',
		path: '/unit',
	},
	utility: {
		name: 'Color',
		path: '/color',
	},
	variation: {
		name: 'Variation',
		path: '/variation',
	},
};
