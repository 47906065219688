export const initialState = {
	data: {
		message: '',
	},
	error: {
		message: null,
	},
	isEdit: false,
	user: {},
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'INPUT':
			return {
				...state,
				data: {
					...state.data,
					[action.payload.name]: action.payload.value,
				},
				error: {
					...state.error,
					[action.payload.name]: false,
				},
			};
		case 'SET_ROOT':
			return {
				...state,
				[action.payload.name]: action.payload.value,
			};
		case 'RESET':
			return initialState;

		case 'VALIDATION_ERROR':
			return {
				...state,
				error: action.payload,
			};
		case 'FILE_SELECT':
			return {
				...state,
				data: {
					...state.data,
				},
			};
		case 'EDIT_DATA':
			return {
				data: {
					...state.data,
					...action.payload,
					status: {
						value: action.payload.status,
					},
					image: {},
				},
				error: {},
				isEdit: true,
			};
		default:
			return state;
	}
};

export const submitData = (state) => {
	const data = {
		message: state?.data?.message,
	};
	return data;
};

export const handleInput = (dispatch, value, name) => {
	return dispatch({
		type: 'INPUT',
		payload: {
			name,
			value,
		},
	});
};
export const setRoot = (dispatch, value, name) => {
	return dispatch({
		type: 'SET_ROOT',
		payload: {
			name,
			value,
		},
	});
};
