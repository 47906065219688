import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../components/footer/Footer';
import AffiliatesSidebar from '../components/sidebar/AffiliatesSidebar';
import TopNavBar from '../components/topNavBar/TopNavBar';
import BarLoaders from '../components/loader/BarLoaders';
import FloatChat from '../components/chat/FloatChat';
import { icon } from '../assets/sos/image';

function AffiliatesLayout() {
	const [sidebar, setSidebar] = useState(true);
	const [chatOpen, setChatOpen] = useState(false);
	const { pathname } = useLocation();

	return (
		<div
			className={`ec-header-fixed ec-sidebar-fixed ec-sidebar-light ec-header-light ${
				sidebar ? 'sidebar-minified-out' : 'sidebar-minified'
			}`}
			id="body"
		>
			<div className="wrapper">
				{/* <!-- LEFT MAIN SIDEBAR --> */}
				<AffiliatesSidebar setSidebar={setSidebar} />
				{!sidebar && (
					<div
						onClick={() => setSidebar((e) => !e)}
						className="sidebarOverlayMenu"
					></div>
				)}

				<div className="ec-page-wrapper">
					{/* <!-- Top Nav Bar --> */}
					<TopNavBar setSidebar={setSidebar} from={'affiliate'} />
					<div className="ec-content-wrapper">
						<React.Suspense fallback={<BarLoaders />}>
							<Outlet />
						</React.Suspense>
					</div>

					<Footer />
				</div>

				{/* footer  */}
				{pathname !== '/affiliates-dashboard/chat' && chatOpen && (
					<FloatChat setChatOpen={setChatOpen} />
				)}

				{pathname !== '/affiliates-dashboard/chat' && (
					<div className="chart__box_footer">
						{!chatOpen && (
							<button
								onClick={() => setChatOpen(true)}
								className="chart__box_footer_icon"
							>
								<img src={icon.message} alt="" />
							</button>
						)}
						{chatOpen && (
							<button
								onClick={() => setChatOpen(false)}
								className="chart__box_footer_icon"
							>
								<img src={icon.messageDark} alt="" />
							</button>
						)}
					</div>
				)}
			</div>
		</div>
	);
}

export default AffiliatesLayout;
