import React, { useContext, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { handleSubMenubar, handlerMenubar } from '../action/actions';
import { adminSidebar } from './sidebar-menu/adminSidebar';
import SidebarLoader from '../loader/SidebarLoader';
import {
	GetAdminSupportData,
	usePermissionManager,
} from '../../api/admin/apiAdmin';
import { UseAuth } from '../../auth/AuthContext';
import { DashboardItem, MenuList, SOS, SubMenuList } from './SidebarItem';
import { useCountSupport } from './common/useCountSupport';

function Sidebar({ setSidebar }) {
	const [menuID, setMenuID] = useState(false);
	const { pathname } = useLocation();
	const { permission, isLoading } = usePermissionManager();
	const { problemTopicData: supportList } = GetAdminSupportData();
	const { user } = useContext(UseAuth);
	const haveDataInSupportList = supportList?.data?.length > 0;

	const permissionList = permission?.permissions?.map((e) => e?.name);
	function isElementInArray(element, array) {
		return array?.includes(element);
	}
	const checkPermission = (permissionRoute, isArray) => {
		if (isArray) {
			return permissionRoute?.some((permission) => {
				return isElementInArray(permission, permissionList);
			});
		}
		return isElementInArray(permissionRoute, permissionList);
	};

	const { sidebarMenu } = useCountSupport(adminSidebar, 'admin');

	return (
		<div className="ec-left-sidebar ec-bg-sidebar">
			<div id="sidebar" className="sidebar ec-sidebar-footer">
				<SOS setSidebar={setSidebar} />
				{isLoading ? (
					<SidebarLoader length={20} />
				) : (
					<div className="ec-navigation" data-simplebar>
						{/* <!-- sidebar menu --> */}
						<ul
							className="nav sidebar-inner"
							style={{ overflowX: 'hidden', overflowY: 'auto' }}
							id="sidebar-menu"
						>
							{/* <!-- Dashboard --> */}
							<DashboardItem active={pathname === '/'} to="/" />

							{sidebarMenu?.map(
								(menu) =>
									checkPermission(menu.permissionRoute, true) && (
										<li
											key={menu.id}
											onClick={(e) =>
												handlerMenubar(menu.id, menuID, setMenuID)
											}
											className={`has-sub ${
												menuID === menu.id &&
												menu.subMenu.length > 0 &&
												'expand'
											}`}
										>
											<MenuList menu={menu} pathname={pathname} />
											<div
												style={{
													display:
														menuID === menu.id &&
														menu.subMenu.length > 0 &&
														'block',
												}}
												className="collapse"
											>
												<ul
													className="sub-menu"
													id="categorys"
													data-parent="#sidebar-menu"
												>
													{menu.subMenu?.map(
														(subMenu, i) =>
															checkPermission(
																subMenu.permissionSubRoute,
																false
															) && (
																<SubMenuList
																	key={i}
																	subMenu={subMenu}
																	handleSubMenubar={handleSubMenubar}
																	pathname={pathname}
																/>
															)
													)}
												</ul>
											</div>
											{menu.hr && <hr />}
										</li>
									)
							)}
							{user?.id !== '1' &&
								haveDataInSupportList &&
								!checkPermission('support', false) && (
									<li className="sidebarNav">
										<NavLink
											className={({ isActive }) =>
												isActive ? 'active-side-main-menu' : ''
											}
											to={'/admin/support-assign'}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="mr-3"
												width="20"
												height="21"
												viewBox="0 0 14 14"
											>
												<g
													fill="none"
													stroke="currentColor"
													strokeLinecap="round"
													strokeLinejoin="round"
												>
													<rect width="3" height="6" x="2.5" y="7.5" rx="1" />
													<rect width="3" height="6" x="8.5" y="7.5" rx="1" />
													<path
														style={{ fill: 'none' }}
														d="M.5 9.5V7a6.5 6.5 0 0 1 13 0v2.5"
													/>
												</g>
											</svg>
											<span className="nav-text">Support Assigned</span>
										</NavLink>
										<hr />
									</li>
								)}
						</ul>
					</div>
				)}
			</div>
		</div>
	);
}

export default Sidebar;
