import React from 'react';
import { CiSearch } from 'react-icons/ci';

function Search({ searchHandler }) {
	return (
		<div
			className="d-flex orderSearchFildWrap align-items-center"
			style={{ gap: '5px' }}
		>
			<input
				onInput={(e) => searchHandler(e.target.value)}
				placeholder="Search"
				className="form-control"
				autoComplete={'off'}
				name="email"
			/>
			<span className="searchIcon">
				<CiSearch />
			</span>
		</div>
	);
}

export default Search;
