import { useLocation } from 'react-router-dom';
import { affiliatesMenu } from './sidebar-menu/affiliatesMenu';
import { DashboardItem, MenuList, SOS, SubMenuList } from './SidebarItem';
import { handleSubMenubar, handlerMenubar } from '../action/actions';
import { useState } from 'react';
import { useCountSupport } from './common/useCountSupport';

function AffiliatesSidebar({ setSidebar }) {
	const { pathname } = useLocation();
	const [menuID, setMenuID] = useState(false);

	const { sidebarMenu } = useCountSupport(affiliatesMenu, 'others');

	return (
		<div className="ec-left-sidebar ec-bg-sidebar">
			<div id="sidebar" className="sidebar ec-sidebar-footer">
				<SOS setSidebar={setSidebar} />

				<div className="ec-navigation" data-simplebar>
					<ul className="nav sidebar-inner" id="sidebar-menu">
						{/* <!-- Dashboard --> */}
						<DashboardItem
							active={pathname === '/affiliates-dashboard'}
							to="/affiliates-dashboard"
						/>
						{sidebarMenu?.map((menu) => (
							<li
								key={menu.id}
								onClick={(e) => handlerMenubar(menu.id, menuID, setMenuID)}
								className={`has-sub ${
									menuID === menu.id && menu.subMenu.length > 0 && 'expand'
								}`}
							>
								<MenuList menu={menu} pathname={pathname} />

								<div
									style={{
										display:
											menuID === menu.id && menu.subMenu.length > 0 && 'block',
									}}
									className="collapse"
								>
									<ul
										className="sub-menu"
										id="categorys"
										data-parent="#sidebar-menu"
									>
										{menu.subMenu?.map((subMenu, i) => (
											<SubMenuList
												key={i}
												subMenu={subMenu}
												handleSubMenubar={handleSubMenubar}
												pathname={pathname}
											/>
										))}
									</ul>
								</div>
								{menu.hr && <hr />}
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
}

export default AffiliatesSidebar;
