import React from 'react';
import SquareLoader from '../components/loader/SquareLoader';
import { apisVendor, useApi } from '../api/common';
import { Navigate } from 'react-router-dom';

// route is from backend given same name
function VendorRoleProtected({ route, children }) {
	// get login user permission lists
	// const { permission, isLoading } = usePermissionManager();
	const { response, isLoading } = useApi({
		endPoint: apisVendor.employee.permissions,
	});

	// all permission into a single array
	// const permissionList = permission?.permissions?.map((e) => e?.name);

	// checking route is into in array or not

	const checkPermission = (permissionRoute) => {
		for (const r of route) {
			// eslint-disable-next-line eqeqeq
			if (permissionRoute?.[r] == 1) {
				return true;
			}
			return false;
		}
		return false;
	};

	// initial loading
	if (isLoading) {
		return (
			<div
				className="d-flex flex-wrap"
				style={{
					background: '#fff',
					zIndex: '1',
					gap: '35px',
					justifyContent: 'space-around',
				}}
			>
				<SquareLoader length={20} />
			</div>
		);
	}

	// if route is here
	if (
		response?.isEmployee === 'yes' &&
		checkPermission(response?.permission?.vendor_role)
	) {
		return children;
	} else if (response?.isEmployee !== 'yes') {
		return children;
	} else {
		return <Navigate to={`/vendors-dashboard`} replace={true} />;
	}
}

export default VendorRoleProtected;
