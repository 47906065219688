import swal from 'sweetalert';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import preview from '../../assets/img/products/vender-upload-thumb-preview.jpg';
import { DOMAIN_URL } from '../env';

// count word
// countWord(word,countNumber)
export const countWord = (payload = '', count = 10) => {
	if (payload?.length >= count) {
		return `${payload?.slice(0, count)}...`;
	} else {
		return payload;
	}
};

// show date time
// time(time).date
// time(time).dateTime
// time(time).time
// export const time = (payload) => {
// 	const localTime = new Date(payload).toLocaleString();
// 	const mainDate = localTime?.split(',')[0];
// 	const mainTime = localTime?.split(',')[1];
// 	const dateTime = localTime;

// 	let lastYer1 = mainDate?.split('/')[2]?.split('')[2];
// 	let lastYer2 = mainDate?.split('/')[2]?.split('')[3];
// 	let year = lastYer1?.concat(lastYer2);

// 	let dayAndMonth = mainDate?.split('/');
// 	let day = dayAndMonth[1] + '/';
// 	let mon = dayAndMonth[0];
// 	let dayAndMon = day.concat(mon);

// 	const date = dayAndMon + '/' + year;
// 	// const date = mainDate;

// 	const splitTime = mainTime?.split(' ');
// 	const AmPm = splitTime?.[2];
// 	const timeSplit = splitTime?.[1]?.split(':');
// 	const hr = timeSplit?.[0];
// 	const min = timeSplit?.[1];

// 	const time = hr + ':'.concat(min) + ' ' + AmPm;

// 	return { date, time, dateTime };
// };
export const time = (payload) => {
	const localTime = new Date(payload);
	const mainDate = localTime?.toLocaleDateString();
	const mainTime = localTime?.toLocaleTimeString();

	const dateTime = localTime.toLocaleString();

	const lastYer1 = mainDate?.slice(-2);
	const year = lastYer1;

	const dayAndMonth = mainDate?.split('/');
	const day = dayAndMonth[1] + '/';
	const mon = dayAndMonth[0];
	const dayAndMon = day + mon;

	const date = dayAndMon + '/' + year;

	// Extract AM or PM
	const AmPm = mainTime?.split(' ')[1];

	const timeSplit = mainTime?.split(' ')[0]?.split(':');
	const hr = timeSplit[0];
	const min = timeSplit[1];

	const time = hr + ':' + min + ' ' + AmPm;

	return { date, time, dateTime };
};

export const timeConvert = (e) => {
	// Input date and time in ISO 8601 format
	const isoDateTime = e;

	// Parse the ISO date and time
	const parsedDateTime = new Date(isoDateTime);

	// Convert to the desired format
	const options = {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	};

	const formattedDateTime = parsedDateTime.toLocaleString('en-US', options);

	return formattedDateTime;
	// 9/13/2023, 8:18 PM
};

export const timeToCovertCurrentTime = (e) => {
	// Input date and time in ISO 8601 format
	const isoDateTime = e;

	// Parse the ISO date and time
	const parsedDateTime = new Date(isoDateTime);

	// Calculate the time difference in milliseconds
	const currentTime = new Date();
	const timeDifference = currentTime - parsedDateTime;

	// Define time intervals
	const minute = 60 * 1000;
	const hour = 60 * minute;
	const day = 24 * hour;
	const month = 30 * day; // Approximate
	const year = 365 * day; // Approximate

	// Function to format the time difference
	function formatRelativeTime(timeDifference) {
		if (timeDifference < minute) {
			// return Math.floor(timeDifference / 1000) + ' sec ago';
			return 'just now';
		} else if (timeDifference < hour) {
			return Math.floor(timeDifference / minute) + ' min';
		} else if (timeDifference < day) {
			return Math.floor(timeDifference / hour) + ' hr';
		} else if (timeDifference < month) {
			return Math.floor(timeDifference / day) + ' days';
		} else if (timeDifference < year) {
			return Math.floor(timeDifference / month) + ' months';
		} else {
			return Math.floor(timeDifference / year) + ' years';
		}
	}

	// Format the relative time
	const relativeTime = formatRelativeTime(timeDifference);

	return relativeTime;
};

// show alert
// alert(title,text)
export const alert = (title, text) => {
	return swal(title, text);
};
export const alertError = (title, text) => {
	return swal(title, text || 'Something Is Wrong Try Again', 'error');
};

// photo view
//  photView(image)
export const photoView = (payload, title = 'View Image') => {
	const url = `${process.env.REACT_APP_IMG_URL}/${payload}`;
	if (payload) {
		return swal({
			title: title,
			content: {
				element: 'img',
				attributes: {
					src: url,
					alt: 'Image',
					class: 'w-100',
				},
			},
		});
	}
	swal(title, 'No Screenshot Provided');
};

// download Image
// downloadImage(image)
export const downloadImage = (payload) => {
	const url = `${process.env.REACT_APP_IMG_URL}/${payload}`;
	return saveAs(url, 'image.jpg');
};
export const codeRender = (html) => {
	const element = document.createElement('div');
	element.innerHTML = html;
	return element.innerText;
};

export const handlerMenubar = (id, menu, dispatch) => {
	if (id === menu) {
		return dispatch(false);
	}
	dispatch(id);
};
export const handleSubMenubar = (e) => {
	e.stopPropagation();
};

// copy to clip board
// const {copy_data, set_copy_data, copied, click_button_handler}=CopyClipboardHandler()
export function CopyClipboardHandler() {
	const [copied, setCopied] = useState(false);
	const [copy_data, set_copy_data] = useState('');

	const click_button_handler = (e) => {
		set_copy_data(e);
		navigator.clipboard.writeText(e);
		setCopied(true);

		setTimeout(() => {
			setCopied(false);
		}, 3000);
	};

	return { copy_data, set_copy_data, copied, click_button_handler };
}

export const StatusAllUpDown = () => {
	const [upDown, setUpDown] = useState(null);
	const arrowHandler = (e) => {
		if (e === null) {
			setUpDown(true);
		} else if (e === true) {
			setUpDown(false);
		} else {
			setUpDown(null);
		}
	};
	return { upDown, arrowHandler };
};

export const ImagePreview = (img) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks

	if (!img) return preview;

	const urlPath = img?.split(':')[0];

	switch (urlPath) {
		case 'blob':
			return img;

		case 'https':
			return img;
		default:
			return `${process.env.REACT_APP_IMG_URL}/${img}`;
		// 		function checkImageLoad(imgUrl, img, preview) {
		// 			return new Promise((resolve, reject) => {
		// 				const IMG = new Image();
		// 				IMG.src = `${imgUrl}/${img}`;

		// 				IMG.onload = () => {
		// 					resolve(`${imgUrl}/${img}`);
		// 				};

		// 				IMG.onerror = () => {
		// 					reject(preview);
		// 				};
		// 			});
		// 		}

		// 		// Usage example
		// 		const imgUrl = process.env.REACT_APP_IMG_URL;
		// 		const imgPath = img;
		// 		const previewImg = preview;

		// 		checkImageLoad(imgUrl, imgPath, previewImg)
		// 			.then((imageUrl) => {})
		// 			.catch((preview) => {});
		// }
	}
};

export const removeNullAndDelete_urlObj = (obj) => {
	const newObj = {};

	for (const key in obj) {
		if (obj[key] !== null && obj[key] !== '' && !key.includes('_url')) {
			newObj[key] = obj[key];
		}
	}

	return newObj;
};

export function getCookie(name) {
	const cookies = document.cookie.split('; ');
	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i].split('=');
		if (cookie[0] === name) {
			return JSON.parse(cookie[1]);
		}
	}
	return null;
}

export function removeCookie2() {
	// Set the expiration date to a past date
	document.cookie = `user_info=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${DOMAIN_URL};`;
}

// Example usage to remove the "user_info" cookie

export function removeCookie(name, domain) {
	const pastDate = new Date(0);
	const expires = 'expires=' + pastDate.toUTCString();
	document.cookie = name + '=;' + expires + ';path=/;domain=' + domain;
}

export function deleteCookie(cookieName) {
	// Split all cookies into an array
	const cookies = document.cookie.split(';');
	// Loop through all cookies
	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i].trim();

		// Check if this cookie is the one to delete
		if (cookie.startsWith(cookieName + '=')) {
			// Get the cookie name and value
			const cookieParts = cookie.split('=');
			const name = cookieParts[0];

			// Set the expiration date to a past date
			document.cookie =
				name +
				'=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=' +
				DOMAIN_URL;
			return; // Exit the loop once the cookie is deleted
		}
	}
}

// Example usage to remove the "user_info" cookie

// export function deleteCookie() {
// 	// Set the expiration date to a past date
// 	document.cookie =
// 		'user_info=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
// }

// export function removeCookie(name, domain) {
// 	const pastDate = new Date(0);
// 	const expires = 'expires=' + pastDate.toUTCString();
// 	document.cookie = name + '=;' + expires + `;path=/;domain=${domain}`;
// }

export function getCookie2(name) {
	const cookies = document.cookie.split('; ');
	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i];
		const [cookieName, cookieValue] = cookie.split('=');

		if (cookieName === name) {
			// Decode the cookie value if needed
			return decodeURIComponent(cookieValue);
		}
	}
	return null; // Cookie not found
}

export const checkUser = (role, sm = null) => {
	switch (role) {
		case '1':
			return sm ? 'admin' : 'Admin';
		case '2':
			return sm ? 'vendor' : 'Merchant ';
		case '3':
			return sm ? 'affiliate' : 'Drop Shipper';
		default:
			return sm ? 'user' : 'User';
	}
};
export const dashboard = (role, needAdmin) => {
	switch (role) {
		case '1':
			return needAdmin ? '/admin' : '/';
		case '2':
			return '/vendors-dashboard';
		case '3':
			return '/affiliates-dashboard';
		default:
			return '/users-dashboard';
	}
};

export const changeOrderStatusText = (status) => {
	switch (status) {
		case 'hold':
			return 'Order Hold';
		case 'pending':
			return 'Order Pending';
		case 'cancel':
			return 'Order Cancel';
		case 'received':
			return 'Order Received';
		case 'progress':
			return 'Order Delivery Processing';
		case 'delivered':
			return 'Product Delivered';
		case 'return':
			return 'Product Return';
		case 'processing':
			return 'Product Processing';
		case 'ready':
			return 'Product Ready';

		default:
			return 'Order Hold';
	}
};
export const changeStatus1toActive = (status) => {
	switch (status) {
		case '1':
		case 1:
			return 'Active';
		case '2':
		case 2:
			return 'Pending';
		case '3':
		case 3:
			return 'Rejected';

		default:
			return 'Status';
	}
};

// check value is search result
export const checkValueOfSearch = (value) => {
	return (value === 'search_reel' ? 'search_result' : value)?.replace(
		/_/g,
		'  '
	);
};

export const number2Fix = (string) => {
	return Number(string ? string : '0').toFixed(2);
};

export const checkManager = (payload) => {
	if (
		payload?.data === 'success' ||
		payload?.message === 'You have no permission to access this page'
	) {
		return false;
	}
	return true;
};

// Function to generate a random string of length n
export function generateRandomString(n = 10) {
	const characters =
		'0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
	let randomString = '';
	for (let i = 0; i < n; i++) {
		randomString += characters.charAt(
			Math.floor(Math.random() * characters.length)
		);
	}
	return randomString;
}

export const getDate = (res) => {
	var originalDate = new Date(res);
	// Desired format: 20-11-2020
	var day = originalDate.getDate().toString().padStart(2, '0');
	var month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
	var year = originalDate.getFullYear().toString();
	var formattedDate = day + '-' + month + '-' + year;

	return formattedDate;
};

export const neverBeZero = (input) => {
	// Check if input is a stringable number
	if (typeof input === 'string' && !isNaN(input)) {
		// Convert string to number
		input = parseFloat(input);
	}

	// Check if input is a number
	if (typeof input === 'number' && !isNaN(input)) {
		// If input is a number, check if it's zero
		return input !== 0 ? input : 1;
	} else {
		// If input is not a number, return 1
		return 1;
	}
};
export const _beZero = (input) => {
	// Check if input is a string able number
	if (typeof input === 'string' && !isNaN(input) && input !== '.') {
		// Convert string to number
		input = parseFloat(input);
	}
	// Check if input is a number
	if (typeof input === 'number' && !isNaN(input) && input !== '.') {
		// If input is a number, check if it's zero
		return input;
	} else {
		// If input is not a number, return 1
		return '';
	}
};
export const beZero = (input) => {
	// Check if input is a stringable number
	if (typeof input === 'string' && !isNaN(input)) {
		// Convert string to number
		input = parseFloat(input);
	}

	// Check if input is a number
	if (typeof input === 'number' && !isNaN(input)) {
		// If input is a number, return input
		return input;
	} else {
		// If input is not a number, return 0
		return '';
	}
};

export function countSomething(fields = [], property) {
	return fields?.reduce((pre, cur) => pre + parseInt(cur[property]) || 0, 0);
}
export function convertNegativeToZero(number) {
	if (number < 0) {
		return 0;
	} else {
		return number;
	}
}

// convert select to label value pair
export const toLabelValuePair = (array) => {
	return array.map((e) => ({
		value: e?.id,
		label: e?.name,
	}));
};

// find max id
export const maxId = (array, field) => {
	const maxId = array?.reduce(
		(pre, cur) => (pre[field] > cur[field] ? pre[field] : cur[field]),
		0
	);
	return maxId + 1;
};

// can not be max by target
export const cannotBeMaxByTarget = (payload, target) => {
	if (parseInt(payload) >= parseInt(target)) {
		return target;
	} else {
		return payload;
	}
};

// qty is low and out of stoke
export const qtyManage = (alertQTy, qty) => {
	if (parseInt(qty) === 0)
		return <span style={{ color: 'red' }}>Out Of Stock</span>;
	if (parseInt(alertQTy) >= parseInt(qty))
		return <span style={{ color: 'red' }}>{qty} Low Qty</span>;
	return qty;
};

export const multiply = (value1, value2) => {
	return parseInt(value1 || 1) * parseInt(value2 || 1);
};

export const capitalizeFirstChar = (str) =>
	str.charAt(0).toUpperCase() + str.substring(1);

// check selected object
export const checkSelectedObj = (object, keys) => {
	return keys.some(
		(key) =>
			object[key] !== null && object[key] !== undefined && object[key] !== ''
	);
};
