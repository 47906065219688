import React, { createContext, useEffect, useState } from 'react';
import {
	deleteCookie,
	getCookie,
	removeCookie,
} from '../components/action/actions';
import { DOMAIN_URL, LIVE_LINK } from '../components/env';
import { AllUserProfileData } from '../api/vendor/apiVendor';

export const UseAuth = createContext();

function AuthContext({ children }) {
	const [role, setRole] = useState(null);
	const [cookie, setCookie] = useState(null);
	const [loading, setLoading] = useState(true);
	const [logoutLoading, setLogOutLoading] = useState(false);
	const { isLoading, refetch, user, error } = AllUserProfileData();

	const er = error?.response?.data?.message;

	const logout = async () => {
		setLoading(true);
		setLogOutLoading(true);
		localStorage.clear();
		setRole(null);
		deleteCookie('user_info');
		removeCookie('userInfo', DOMAIN_URL);
		window.location.href = `${LIVE_LINK}/login`;
	};

	if (er === 'Unauthenticated.') {
		logout();
	}

	useEffect(() => {
		const get_role_cookie = async () => {
			setLoading(true);
			const cK = await getCookie('userInfo');

			if (cK) {
				setCookie(cK);
				setRole(cK.role);
				setLoading(false);
			} else {
				setCookie(null);
				setRole(null);
				setLoading(false);
				window.location.href = `${LIVE_LINK}/login`;
			}
		};
		get_role_cookie();
	}, []);

	// when error going to Infinity loop
	// if (isError) {
	// 	return logout();
	// }
	const value = {
		setRole,
		loading,
		setLoading,
		logout,
		logoutLoading,
		cookie,
		role,
		user,
		refetch,
		isLoading,
	};

	return <UseAuth.Provider value={value}>{children}</UseAuth.Provider>;
}

export default AuthContext;
