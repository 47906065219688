import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import SignInV2 from '../pages/sign-in/sign-in-v2/SignInV2';
import RegisterV2 from '../pages/sign-in/register-v2/RegisterV2';
import PrivateRoutes from './PrivateRoutes';
import CheckPermission from './CheckPermission';
import MainLayout from '../layouts/MainLayout';
import VendorLayout from '../layouts/VendorLayout';
import AffiliatesLayout from '../layouts/AffiliatesLayout';
import ThreeDots from '../components/loader/ThreeDots';
import NotFound from '../pages/404/NotFound';
import VendorRoleProtected from './VendorRoleProtected';
import { vendorRoutes } from './all-routes';
// for error chunk
const lazyRetry = function (componentImport) {
	return new Promise((resolve, reject) => {
		const hasRefreshed = JSON.parse(
			window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
		);

		componentImport()
			.then((component) => {
				window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
				resolve(component);
			})
			.catch((error) => {
				if (!hasRefreshed) {
					// not been refreshed yet
					window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
					return window.location.reload(); // refresh the page
				}
				reject(error);
			});
	});
};

const lazyLoad = (importStatement) => {
	return React.lazy(() => lazyRetry(importStatement));
};
const Customer = lazyLoad(() => import('../components/sales/customer'));
const ProductAdd = lazyLoad(() =>
	import('../components/product-add/ProductAdd')
);
const PaymentMethods = lazyLoad(() => import('../components/payment-methods'));
const Purchase = lazyLoad(() => import('../components/purchase/Purchase'));
const ManagePurchase = lazyLoad(() =>
	import('../components/purchase/ManagePurchase')
);
const PurchaseInvoice = lazyLoad(() =>
	import('../components/purchase/PurchaseInvoice')
);
const SalesReturn = lazyLoad(() =>
	import('../components/seles-return/SalesReturn')
);
const ReturnList = lazyLoad(() => import('../components/purchase/ReturnList'));
const InvoiceGenerate = lazyLoad(() =>
	import('../components/purchase/InvoiceGenerate')
);
const ManageSales = lazyLoad(() =>
	import('../components/sales/manage-purchase/ManageSales')
);
const PosSales = lazyLoad(() =>
	import('../components/sales/pos-sales/PosSales')
);
const ReturnSales = lazyLoad(() =>
	import('../components/sales/return-sales/Index')
);
const OrderSource = lazyLoad(() => import('../components/order-source'));
const SalesInvoice = lazyLoad(() =>
	import('../components/sales/manage-purchase/SalesInvoice')
);
const SalesHistory = lazyLoad(() => import('../components/sales/SalesHistory'));
const PurchaseHistory = lazyLoad(() =>
	import('../components/purchase/PurchaseHistory')
);
const Chat = lazyLoad(() => import('../components/chat/Chat'));
const PosSalesReturn = lazyLoad(() =>
	import('../components/sales/return-sales/PosSalesReturn')
);

const Wastage = lazyLoad(() => import('../components/westage/Wastage'));
const AddWastage = lazyLoad(() => import('../components/westage/AddWastage'));
const NewOrder = lazyLoad(() => import('../components/new-order/NewOrder'));
const NewOrderInvoice = lazyLoad(() =>
	import('../components/invoice/new-order/NewOrderInvoice')
);
const NewOrderReturn = lazyLoad(() =>
	import('../components/new-order/NewOrderReturn')
);
const StockReport = lazyLoad(() => import('../components/report/StockReport'));
const SalesReport = lazyLoad(() => import('../components/report/SalesReport'));
const DueSalesReport = lazyLoad(() =>
	import('../components/report/DueSalesReport')
);
const PurchaseReport = lazyLoad(() =>
	import('../components/report/PurchaseReport')
);
const WarehouseReport = lazyLoad(() =>
	import('../components/report/WarehouseReport')
);
const Employee = lazyLoad(() => import('../components/employee/Employee'));
const ProductListV2 = lazyLoad(() =>
	import('../components/productsList/v2/ProductListV2')
);

const OrderExchange = lazyLoad(() =>
	import('../components/order-exchange/OrderExchange')
);
const CreateEmployee = lazyLoad(() =>
	import('../components/employee/CreateEmployee')
);
const Unit = lazyLoad(() => import('../components/unit'));
const Variation = lazyLoad(() => import('../components/variatoin'));
const Category = lazyLoad(() => import('../components/category/Category'));
const SubCategory = lazyLoad(() =>
	import('../components/category/SubCategory')
);
const Brand = lazyLoad(() => import('../components/brand'));
const ServiceCategory = lazyLoad(() =>
	import('../components/service-category/ServiceCategory')
);
const ServiceSubCategory = lazyLoad(() =>
	import('../components/service-category/ServiceSubCategory')
);
const SupportCategory = lazyLoad(() =>
	import('../components/support-category/SupportCategory')
);
const SupportSubCategory = lazyLoad(() =>
	import('../components/support-category/SupportSubCategory')
);
const HomeContentService = lazyLoad(() =>
	import('../components/frontend/home-content/service')
);
const HomeContentOrganization = lazyLoad(() =>
	import('../components/frontend/home-content/organization')
);
const HomeContentItService = lazyLoad(() =>
	import('../components/frontend/home-content/it-service')
);

const HomeContentOrganizationTwo = lazyLoad(() =>
	import('../components/frontend/home-content/organization-two')
);
const HomeContentPartner = lazyLoad(() =>
	import('../components/frontend/home-content/partner')
);
const AboutContentCompanions = lazyLoad(() =>
	import('../components/frontend/about-content/companion')
);
const AboutContentMission = lazyLoad(() =>
	import('../components/frontend/about-content/mission')
);
const AdvertiserContentFaq = lazyLoad(() =>
	import('../components/frontend/advertiser-content/faq')
);
const AboutContentTestimonial = lazyLoad(() =>
	import('../components/frontend/about-content/testimonial')
);
const AboutContentMember = lazyLoad(() =>
	import('../components/frontend/about-content/member')
);
const HomeContentFooterMedia = lazyLoad(() =>
	import('../components/frontend/home-content/footer')
);
const PosSalesBarcodeGenerator = lazyLoad(() =>
	import('../components/sales/pos-sales-barcode-generate')
);
const BarCodeLists = lazyLoad(() =>
	import('../components/sales/pos-sales-barcode-generate/BarCodeLists')
);
const ProductView2 = lazyLoad(() => import('../components/product-view-2'));
const EmployeePErmission = lazyLoad(() =>
	import('../pages/vendor-dashboard/employee-permission/EmployeePErmission')
);
const VendorOrderHistoryReturn = lazyLoad(() =>
	import('../pages/vendor-dashboard/orders-history/VendorOrderHistoryReturn')
);
const VendorOrderHistoryReady = lazyLoad(() =>
	import('../pages/vendor-dashboard/orders-history/VendorOrderHistoryReady')
);
const VendorOrderHistoryProcessing = lazyLoad(() =>
	import(
		'../pages/vendor-dashboard/orders-history/VendorOrderHistoryProcessing'
	)
);
const OrderHistoryReady = lazyLoad(() =>
	import('../pages/affiliates-dashboard/orders-history/OrderHistoryReady')
);
const OrderHistoryProcessing = lazyLoad(() =>
	import('../pages/affiliates-dashboard/orders-history/OrderHistoryProcessing')
);
const OrderHistoryReturn = lazyLoad(() =>
	import('../pages/affiliates-dashboard/orders-history/OrderHistoryRetrun')
);
const AdminOrderHistoryProcessing = lazyLoad(() =>
	import('../pages/admin-dashboard/orders-history/AdminOrderHistoryProcessing')
);
const AdminOrderHistoryReady = lazyLoad(() =>
	import('../pages/admin-dashboard/orders-history/AdminOrderHistoryReady')
);
const AdminOrderHistoryReturn = lazyLoad(() =>
	import('../pages/admin-dashboard/orders-history/AdminOrderHistoryReturn')
);

const Damage = lazyLoad(() => import('../components/damage'));
const DamageLIst = lazyLoad(() => import('../components/damage/DamageLIst'));
const SalesReportDaily = lazyLoad(() =>
	import('../components/report/SalesReportDaily')
);
const TopRepeatCustomer = lazyLoad(() =>
	import('../components/report/TopRepeatCustomer')
);
const StockShortageReport = lazyLoad(() =>
	import('../components/report/StockShortageReport')
);
const DeliveryCharge = lazyLoad(() => import('../components/delivery-charge'));
const DeliveryCompany = lazyLoad(() =>
	import('../components/delivery-company')
);
const ProfileView = lazyLoad(() => import('../components/profile-view'));
const DeliveryArea = lazyLoad(() => import('../components/delivery-area'));
const PickupArea = lazyLoad(() => import('../components/pick-area'));
const Invoice = lazyLoad(() => import('../components/invoice/Invoice'));
const AdminRoleProtected = lazyLoad(() => import('./AdminRoleProtected'));
const AddPurchase = lazyLoad(() =>
	import('../pages/vendor-dashboard/purchase/AddPurchase/AddPurchase')
);
const Utility = lazyLoad(() => import('../components/utility'));
const WareHouse = lazyLoad(() => import('../components/warehouse'));
const Supplier = lazyLoad(() => import('../components/supplier'));

const MyServiceOrderVendor = lazyLoad(() =>
	import('../pages/vendor-dashboard/service/my-service/MyServiceOrderVendor')
);
const RolePermission = lazyLoad(() =>
	import('../pages/admin-dashboard/role-permission/RolePermission')
);
const MyServiceOrderAffiliate = lazyLoad(() =>
	import(
		'../pages/affiliates-dashboard/service/my-service/MyServiceOrderAffiliate'
	)
);

const MyServiceOrderUser = lazyLoad(() =>
	import('../pages/users-dashboard/service/MyServiceOrderUser')
);

const ServiceOrderAffiliate = lazyLoad(() =>
	import(
		'../pages/affiliates-dashboard/service/create-and-manage/ServiceOrderAffiliate'
	)
);
const ServiceDeliveryVendor = lazyLoad(() =>
	import(
		'../pages/vendor-dashboard/service/create-and-manage/ServiceDeliveryVendor'
	)
);
const ServiceViewVendor = lazyLoad(() =>
	import(
		'../pages/vendor-dashboard/service/create-and-manage/ServiceViewVendor'
	)
);
const ServiceView = lazyLoad(() =>
	import('../components/service/view/ServiceView')
);
const AllService = lazyLoad(() =>
	import('../components/service/all/AllService')
);
const ServiceDeliveryView = lazyLoad(() =>
	import('../components/service/delivery-view/ServiceDeliveryView')
);
const ServiceCreate = lazyLoad(() =>
	import('../components/service/create/ServiceCreate')
);
const UpdateService = lazyLoad(() =>
	import('../components/service/edit/UpdateService')
);
const MyServiceOrder = lazyLoad(() =>
	import('../components/service/order/MyServiceOrder')
);
const UsersServiceOrderManage = lazyLoad(() =>
	import('../components/service/order/UsersServiceOrderManage')
);
const GetUserRequestRejectedCoupon = lazyLoad(() =>
	import(
		'../pages/admin-dashboard/user-request-coupon/GetUserRequestRejectedCoupon'
	)
);
const EditCoupon = lazyLoad(() =>
	import('../pages/admin-dashboard/update-content/AdminCoupon/EditCoupon')
);
const OrderHistoryRecived = lazyLoad(() =>
	import('../pages/affiliates-dashboard/orders-history/OrderHistoryRecived')
);
const AdminSetting = lazyLoad(() =>
	import('../pages/admin-dashboard/setting/AdminSetting')
);
const AdminOrderHistoryReceived = lazyLoad(() =>
	import('../pages/admin-dashboard/orders-history/AdminOrderHistoryReceived')
);
const VendorOrderHistoryReceived = lazyLoad(() =>
	import('../pages/vendor-dashboard/orders-history/VendorOrderHistoryReceived')
);
const AdvertiseAllAdmin = lazyLoad(() =>
	import('../pages/admin-dashboard/advertise/AdvertiseAllAdmin')
);
const AdvertiseUtility = lazyLoad(() =>
	import('../components/advertise/AdvertiseUtility')
);

const AdvertiseAllVendor = lazyLoad(() =>
	import('../pages/vendor-dashboard/vendor-advertiser/AdvertiseAllVendor')
);

const AdvertiseAllAffiliate = lazyLoad(() =>
	import(
		'../pages/affiliates-dashboard/affiliates-advertiser/AdvertiseAllAffiliate'
	)
);

const AdvertiseAllUser = lazyLoad(() =>
	import('../pages/users-dashboard/user-advertiser/AdvertiseAllUser')
);

const AdvertiseViewAdmin = lazyLoad(() =>
	import('../pages/admin-dashboard/advertise/AdvertiseViewAdmin')
);

const AdvertiseViewAffiliate = lazyLoad(() =>
	import(
		'../pages/affiliates-dashboard/affiliates-advertiser/AdvertiseViewAffiliate'
	)
);

const AdvertiseViewUser = lazyLoad(() =>
	import('../pages/users-dashboard/user-advertiser/AdvertiseViewUser')
);

const AdvertiseViewVendor = lazyLoad(() =>
	import('../pages/vendor-dashboard/vendor-advertiser/AdvertiseViewVendor')
);

const AdvertiseEditAdmin = lazyLoad(() =>
	import('../pages/admin-dashboard/advertise/AdvertiseEditAdmin')
);

const AdminWithdraw = React.lazy(() =>
	lazyRetry(() => import('../pages/admin-dashboard/withdraw/AdminWithdraw'))
);

const AffiPendingOrder = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/affiliates-dashboard/affiliate-own-order/Others/AffiPendingOrder'
		)
	)
);
const VendorEditedProductAdminView = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/admin-dashboard/vendor-products/vendor-edited-product/VendorEditedProductAdminView'
		)
	)
);
const AllAdminUsers = React.lazy(() =>
	lazyRetry(() => import('../pages/admin-dashboard/all-users/AllAdminUsers'))
);
const VendorEditedProductAdmin = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/admin-dashboard/vendor-products/vendor-edited-product/VendorEditedProductAdmin'
		)
	)
);
const VendorWithdraw = React.lazy(() =>
	lazyRetry(() => import('../pages/vendor-dashboard/wallet/VendorWithdraw'))
);
const VendorEditedProduct = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/vendor-dashboard/products/edited-product/VendorEditedProduct'
		)
	)
);
const VendorProductEditedView = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/vendor-dashboard/products/edited-product/VendorProductEditedView'
		)
	)
);
const AffiProgressOrder = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/affiliates-dashboard/affiliate-own-order/Others/AffiProgressOrder'
		)
	)
);
const AffiHoldOrder = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/affiliates-dashboard/affiliate-own-order/Others/AffiHoldOrder'
		)
	)
);

const PendingOrder = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/users-dashboard/user-service-order/PendingOrder')
	)
);
const ProgressOrder = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/users-dashboard/user-service-order/ProgressOrder')
	)
);
const HoldOrder = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/users-dashboard/user-service-order/HoldOrder')
	)
);
const CancelOrder = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/users-dashboard/user-service-order/CancelOrder')
	)
);
const UserDashboard = React.lazy(() =>
	lazyRetry(() => import('../pages/users-dashboard/user-swith/UserDashboard'))
);
const SwitchOption = React.lazy(() =>
	lazyRetry(() => import('../pages/users-dashboard/user-swith/SwitchOption'))
);
const SwitchCheckout = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/users-dashboard/user-swith/Checkout/SwitchCheckout')
	)
);
const GetAllWithdrawHistory = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/users-dashboard/user-withdraw/GetAllWithdrawHistory')
	)
);
const GetPendingWithdraw = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/users-dashboard/user-withdraw/GetPendingWithdraw')
	)
);
const GetSuccessWithdraw = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/users-dashboard/user-withdraw/GetSuccessWithdraw')
	)
);
const AffiliateRecharge = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/affiliate-recharge/AffiliateRecharge')
	)
);
const GetUserRequestCoupon = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/user-request-coupon/GetUserRequestCoupon')
	)
);
const UsersWithdraw = React.lazy(() =>
	lazyRetry(() => import('../pages/users-dashboard/wallet/UsersWithdraw'))
);
const UserCoponRequest = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/users-dashboard/coupon-request/UserCoponRequest')
	)
);
const GetMembership = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/vendor-affiliate-membership/GetMembership')
	)
);
const AffiliateExpiredProducts = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/vendor-dashboard/affiliate-requested/AffiliateExpiredProducts'
		)
	)
);
const ExpiredProductAffiliate = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/products/ExpiredProductAffiliate')
	)
);

const VendorServiceDelivery = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/vendor-dashboard/vendor-own-service/VendorServiceDelivery')
	)
);

const UserProfile = React.lazy(() =>
	lazyRetry(() => import('../pages/users-dashboard/user-profile/UserProfile'))
);

const AdminAddNewUser = React.lazy(() =>
	lazyRetry(() => import('../pages/admin-dashboard/user/AdminAddNewUser'))
);
const ActiveUsers = React.lazy(() =>
	lazyRetry(() => import('../pages/admin-dashboard/user/ActiveUsers'))
);
const PendingUsers = React.lazy(() =>
	lazyRetry(() => import('../pages/admin-dashboard/user/PendingUsers'))
);
const UserProfileView = React.lazy(() =>
	lazyRetry(() => import('../pages/admin-dashboard/user/UserProfileView'))
);
const AllUsers = React.lazy(() =>
	lazyRetry(() => import('../pages/admin-dashboard/user/AllUsers'))
);
const VendorRecharge = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/vendor-dashboard/vendor-recharge/VendorRecharge')
	)
);
const UserRecharge = React.lazy(() =>
	lazyRetry(() => import('../pages/users-dashboard/user-recharge/UserRecharge'))
);
const GetAffiliateHistory = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/wallet/GetAffiliateHistory')
	)
);
const GetVendorHistory = React.lazy(() =>
	lazyRetry(() => import('../pages/vendor-dashboard/wallet/GetVendorHistory'))
);
const GetUserHistory = React.lazy(() =>
	lazyRetry(() => import('../pages/users-dashboard/wallet/GetUserHistory'))
);

const ServiceDelivery = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/my-service/ServiceDelivery')
	)
);
const UserLayout = React.lazy(() =>
	lazyRetry(() => import('../layouts/UserLayout'))
);
const UserAllSupport = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/users-dashboard/user-support/UserAllSupport')
	)
);
const CreateTicket = React.lazy(() =>
	lazyRetry(() => import('../pages/users-dashboard/user-support/CreateTicket'))
);

const UserSingleSupport = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/users-dashboard/user-support/UserSingleSupport')
	)
);

const VendorCheckout = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/vendor-dashboard/renew/checkout/VendorCheckout')
	)
);

const AffiCheckout = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/affi-renew/checkout/AffiCheckout')
	)
);
const AffiRenew = React.lazy(() =>
	lazyRetry(() => import('../pages/affiliates-dashboard/affi-renew/AffiRenew'))
);
const Renew = React.lazy(() =>
	lazyRetry(() => import('../pages/vendor-dashboard/renew/Renew'))
);
const SubscriptionUpdate = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/admin-dashboard/update-content/Subscription/SubscriptionUpdate'
		)
	)
);
const GetVendorSingleService = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/vendor-service/GetVendorSingleService')
	)
);
const GetVendorCoupon = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/vendor-dashboard/vendor-all-coupon/GetVendorCoupon')
	)
);
const AdvertiserDelivary = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/admin-dashboard/update-content/AdvertiseContent/GetAdvertiser/AdvertiserDelivary'
		)
	)
);
const GetAffiliatesCoupon = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/affiliates-dashboard/affiliates-all-coupon/GetAffiliatesCoupon'
		)
	)
);

const GetSubscription = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/admin-dashboard/update-content/Subscription/GetSubscription'
		)
	)
);
const AffiliatesCreateSupport = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/affiliates-dashboard/affiliates-support/AffiliatesCreateSupport'
		)
	)
);
const AffiliatesAllSupport = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/affiliates-dashboard/affiliates-support/AffiliatesAllSupport'
		)
	)
);
const AffiliatesSingleSupport = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/affiliates-dashboard/affiliates-support/AffiliatesSingleSupport'
		)
	)
);

const AdminAllSupport = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/support/supports/AdminAllSupport')
	)
);
const AdminSingleSupport = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/support/supports/AdminSingleSupport')
	)
);
const CreateSupport = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/vendor-dashboard/Vendorsupport/CreateSupport')
	)
);
const AllSupport = React.lazy(() =>
	lazyRetry(() => import('../pages/vendor-dashboard/Vendorsupport/AllSupport'))
);
const VendorSingleSupport = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/vendor-dashboard/Vendorsupport/VendorSingleSupport')
	)
);
const CreateCoupon = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/update-content/AdminCoupon/CreateCoupon')
	)
);

const AdminCoupon = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/update-content/AdminCoupon/AdminCoupon')
	)
);

const GetContactMessage = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/admin-dashboard/update-content/ContactMessage/GetContactMessage'
		)
	)
);

const CreateFooter = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/admin-dashboard/update-content/Service-Home/Footer/CreateFooter'
		)
	)
);
const ServiceContentUpdate = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/admin-dashboard/update-content/ServiceUpdate/ServiceContentUpdate'
		)
	)
);
const AdvertiseContentUpdate = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/admin-dashboard/update-content/AdvertiseContent/AdvertiseContentUpdate'
		)
	)
);
const HomeContent = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/admin-dashboard/update-content/HomeUpdateContent/HomeContent'
		)
	)
);
const AboutUpdateContent = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/admin-dashboard/update-content/About-update-content/AboutUpdateContent'
		)
	)
);
const GeneralContentUpdate = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/admin-dashboard/update-content/GeneralContentUpdate/GeneralContentUpdate'
		)
	)
);

const AllDeposit = React.lazy(() =>
	lazyRetry(() => import('../pages/vendor-dashboard/diposit/AllDeposit'))
);
const AffiliateBalanceHistory = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/income/AffiliateBalanceHistory')
	)
);
const AdminDashboard = React.lazy(() =>
	lazyRetry(() => import('../pages/admin-dashboard/AdminDashboard'))
);
const Affiliates = React.lazy(() =>
	lazyRetry(() => import('../pages/admin-dashboard/affiliates/AllAffiliates'))
);

// const Brand = React.lazy(() =>
// 	lazyRetry(() => import('../pages/admin-dashboard/brands/Brands'))
// );
const AdminProfile = React.lazy(() =>
	lazyRetry(() => import('../pages/admin-dashboard/profile/AdminProfile'))
);

const VendorEdit = React.lazy(() =>
	lazyRetry(() => import('../pages/admin-dashboard/vendor/VendorEdit'))
);
const AffiliatesDashboard = React.lazy(() =>
	lazyRetry(() => import('../pages/affiliates-dashboard/AffiliatesDashboard'))
);

const AddToCart = React.lazy(() =>
	lazyRetry(() => import('../pages/affiliates-dashboard/cart/AddToCart'))
);
const ActiveDetails = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/products/ActiveProduct/ActiveDetails')
	)
);
const PendingDetails = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/affiliates-dashboard/products/PendingProduct/PendingDetails'
		)
	)
);
const ProductDetailsAll = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/affiliates-dashboard/products/allProducts/ProductDetailsAll'
		)
	)
);
const AffiliatesProfile = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/profile/AffiliatesProfile')
	)
);

const VendorsDashboard = React.lazy(() =>
	lazyRetry(() => import('../pages/vendor-dashboard/VendorsDashboard'))
);

const AdminAddNewAffiliates = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/affiliates/AdminAddNewAffiliates')
	)
);

const AdminAddNewVendor = React.lazy(() =>
	lazyRetry(() => import('../pages/admin-dashboard/vendor/AdminAddNewVendor'))
);
const Checkout = React.lazy(() =>
	lazyRetry(() => import('../pages/affiliates-dashboard/checkout/Checkout'))
);
const ActiveVendor = React.lazy(() =>
	lazyRetry(() => import('../pages/admin-dashboard/vendor/ActiveVendor'))
);
const PendingVendor = React.lazy(() =>
	lazyRetry(() => import('../pages/admin-dashboard/vendor/PendingVendor'))
);
const ActiveAffiliates = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/affiliates/ActiveAffiliates')
	)
);
const PendingAffiliates = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/affiliates/PendingAffiliates')
	)
);
const View = React.lazy(() =>
	lazyRetry(() => import('../pages/admin-dashboard/view-profile/View'))
);
const AllProductsVendor = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/vendor-products/AllProductsVendor')
	)
);
const ActiveProductsVendor = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/vendor-products/ActiveProductsVendor')
	)
);
const PendingProductsVendor = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/vendor-products/PendingProductsVendor')
	)
);
const AllRequestAffiliate = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/affiliates-products/AllRequestAffiliate')
	)
);
const ActiveRequestAffiliate = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/admin-dashboard/affiliates-products/ActiveRequestAffiliate'
		)
	)
);
const PendingRequestAffiliate = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/admin-dashboard/affiliates-products/PendingRequestAffiliate'
		)
	)
);

const VendorActiveProduct = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/vendor-dashboard/products/VendorActiveProduct')
	)
);
const VendorPendingProduct = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/vendor-dashboard/products/VendorPendingProduct')
	)
);
const VendorProfileView = React.lazy(() =>
	lazyRetry(() => import('../pages/admin-dashboard/vendor/VendorProfileView'))
);
const AffiliatesProfileView = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/affiliates/AfiiliatesProfileView')
	)
);
const RejectedProductsVendor = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/vendor-products/RejectedProductsVendor')
	)
);
const VendorProductView = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/vendor-products/VendorProductView')
	)
);
const VendorRejectedProduct = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/vendor-dashboard/products/VendorRejectedProduct')
	)
);
const VendorProductPreview = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/vendor-dashboard/products/VendorProductPreview')
	)
);
const AffiliateAllRequest = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/vendor-dashboard/affiliate-requested/AffiliateAllRequest')
	)
);
const AffiliateActiveRequest = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/vendor-dashboard/affiliate-requested/AffiliateActiveRequest'
		)
	)
);
const AffiliatePendingRequest = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/vendor-dashboard/affiliate-requested/AffiliatePendingRequest'
		)
	)
);
const AffiliateRejectedRequest = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/vendor-dashboard/affiliate-requested/AffiliateRejectedRequest'
		)
	)
);
const OrderHistoryAll = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/orders-history/OrderHistoryAll')
	)
);
const OrderHistoryPending = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/orders-history/OrderHistoryPending')
	)
);
const OrderHistoryProgress = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/orders-history/OrderHistoryProgress')
	)
);
const OrderHistoryDelivered = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/orders-history/OrderHistoryDelivered')
	)
);
const OrderHistoryCancel = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/orders-history/OrderHistoryCancel')
	)
);
const AdminOrderHistoryAll = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/orders-history/AdminOrderHistoryAll')
	)
);
const AdminOrderHistoryPending = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/orders-history/AdminOrderHistoryPending')
	)
);
const AdminOrderHistoryProgress = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/orders-history/AdminOrderHistoryProgress')
	)
);
const AdminOrderHistoryDelivered = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/orders-history/AdminOrderHistoryDelivered')
	)
);
const AdminOrderHistoryCancel = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/orders-history/AdminOrderHistoryCancel')
	)
);
const VendorOrderHistoryCancel = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/vendor-dashboard/orders-history/VendorOrderHistoryCancel')
	)
);
const VendorOrderHistoryDelivered = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/vendor-dashboard/orders-history/VendorOrderHistoryDelivered'
		)
	)
);
const VendorOrderHistoryProgress = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/vendor-dashboard/orders-history/VendorOrderHistoryProgress'
		)
	)
);
const VendorOrderHistoryPending = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/vendor-dashboard/orders-history/VendorOrderHistoryPending')
	)
);
const VendorOrderHistoryAll = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/vendor-dashboard/orders-history/VendorOrderHistoryAll')
	)
);
const VendorProfile = React.lazy(() =>
	lazyRetry(() => import('../pages/vendor-dashboard/profile/VendorProfile'))
);
const AllProductAffiliate = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/products/AllProductAffiliate')
	)
);
const ActiveProductAffiliate = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/products/ActiveProductAffiliate')
	)
);
const PendingProductAffiliate = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/products/PendingProductAffiliate')
	)
);
const RejectedProductAffiliate = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/products/RejectedProductAffiliate')
	)
);
const Vendors = React.lazy(() =>
	lazyRetry(() => import('../pages/admin-dashboard/vendor/AllVendors'))
);
const ViewRequestProduct = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/affiliates-products/ViewRequestProduct')
	)
);
const RejectedRequestAffiliate = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/admin-dashboard/affiliates-products/RejectedRequestAffiliate'
		)
	)
);
const AffiliateRequestView = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/vendor-dashboard/affiliate-requested/AffiliateRequestView')
	)
);
const AdminOrderHistoryView = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/orders-history/AdminOrderHistoryView')
	)
);
const VendorOrderHistoryView = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/vendor-dashboard/orders-history/VendorOrderHistoryView')
	)
);
const AffiliateOrderHistoryView = React.lazy(() =>
	lazyRetry(() =>
		import(
			'../pages/affiliates-dashboard/orders-history/AffiliateOrderHistoryView'
		)
	)
);
const VendorOrderHistoryHold = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/vendor-dashboard/orders-history/VendorOrderHistoryHold')
	)
);
const AdminOrderHistoryHold = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/admin-dashboard/orders-history/AdminOrderHistoryHold')
	)
);
const OrderHistoryHold = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/orders-history/OrderHistoryHoldAfi')
	)
);
const DepositBalance = React.lazy(() =>
	lazyRetry(() => import('../pages/vendor-dashboard/diposit/DepositBalance'))
);
const CancelDeposit = React.lazy(() =>
	lazyRetry(() => import('../pages/vendor-dashboard/diposit/CancelDeposit'))
);
const AcceptDeposit = React.lazy(() =>
	lazyRetry(() => import('../pages/vendor-dashboard/diposit/AcceptDeposit'))
);
const PendingDeposit = React.lazy(() =>
	lazyRetry(() => import('../pages/vendor-dashboard/diposit/PendingDeposit'))
);
const AffiliateAvailableIncome = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/income/AffiliateAvailableIncome')
	)
);
const AffiliatePendingIncome = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/income/AffiliatePendingIncome')
	)
);
const AffiliateWithdrawIncome = React.lazy(() =>
	lazyRetry(() =>
		import('../pages/affiliates-dashboard/wallet/AffiliateWithdrawIncome')
	)
);

const route = createBrowserRouter([
	// admin  sing in sing up
	{
		path: '/',
		element: (
			<React.Suspense
				fallback={
					<div className="d-flex justify-content-center align-items-center h-100 w-100">
						<ThreeDots />
					</div>
				}
			>
				<PrivateRoutes>
					<CheckPermission roleID="1">
						<MainLayout />
					</CheckPermission>
				</PrivateRoutes>
			</React.Suspense>
		),
		children: [
			// main dashboard
			{
				path: '/',
				element: <AdminDashboard />,
			},

			// all users
			{
				path: '/admin/all-user',
				element: (
					<AdminRoleProtected route={['alluser']}>
						<AllAdminUsers />
					</AdminRoleProtected>
				),
			},

			// view user
			{
				path: '/admin/user-profile-view/:id',
				element: (
					<AdminRoleProtected
						route={['alluser', 'all-user', 'active-user', 'pending-user']}
					>
						<UserProfileView />
					</AdminRoleProtected>
				),
			},

			// view vendor
			{
				path: '/admin/vendor-profile-view/:id',
				element: (
					<AdminRoleProtected
						route={['alluser', 'all-vendor', 'active-vendor', 'pending-vendor']}
					>
						<VendorProfileView />
					</AdminRoleProtected>
				),
			},
			{
				path: '/admin/profile-view/:id',
				element: (
					<AdminRoleProtected
						route={['alluser', 'all-vendor', 'active-vendor', 'pending-vendor']}
					>
						<ProfileView />
					</AdminRoleProtected>
				),
			},

			// view affiliate
			{
				path: '/admin/affiliator-profile-view/:id',
				element: (
					<AdminRoleProtected
						route={[
							'alluser',
							'all-affiliate',
							'active-affiliate',
							'pending-affiliate',
						]}
					>
						<AffiliatesProfileView />
					</AdminRoleProtected>
				),
			},

			// edit [user vendor affiliate ]
			{
				path: '/admin/edit/:id',
				element: (
					<AdminRoleProtected
						route={[
							'alluser',
							'all-user',
							'active-user',
							'pending-user',
							'all-vendor',
							'active-vendor',
							'pending-vendor',
							'all-affiliate',
							'active-affiliate',
							'pending-affiliate',
						]}
					>
						<VendorEdit />
					</AdminRoleProtected>
				),
			},

			// add vendor
			{
				path: '/admin/vendor/add-new',
				element: (
					<AdminRoleProtected route={['add-vendor']}>
						<AdminAddNewVendor />
					</AdminRoleProtected>
				),
			},

			// all vendor
			{
				path: '/admin/vendors/all-vendors',
				element: (
					<AdminRoleProtected route={['all-vendor']}>
						<Vendors />
					</AdminRoleProtected>
				),
			},

			// active vendor
			{
				path: '/admin/vendors/active-vendor',
				element: (
					<AdminRoleProtected route={['active-vendor']}>
						<ActiveVendor />
					</AdminRoleProtected>
				),
			},

			// pending vendor
			{
				path: '/admin/vendors/pending-vendor',
				element: (
					<AdminRoleProtected route={['pending-vendor']}>
						<PendingVendor />
					</AdminRoleProtected>
				),
			},

			// add new affiliate
			{
				path: '/admin/affiliator/add-new',
				element: (
					<AdminRoleProtected route={['add-affiliate']}>
						<AdminAddNewAffiliates />
					</AdminRoleProtected>
				),
			},

			// all affiliate
			{
				path: '/admin/all-affiliates',
				element: (
					<AdminRoleProtected route={['all-affiliate']}>
						<Affiliates />
					</AdminRoleProtected>
				),
			},

			// active affiliate
			{
				path: '/admin/active-affiliates',
				element: (
					<AdminRoleProtected route={['active-affiliate']}>
						<ActiveAffiliates />
					</AdminRoleProtected>
				),
			},

			// pending affiliate
			{
				path: '/admin/pending-affiliates',
				element: (
					<AdminRoleProtected route={['pending-affiliate']}>
						<PendingAffiliates />
					</AdminRoleProtected>
				),
			},

			// all user
			{
				path: '/admin/all-users',
				element: (
					<AdminRoleProtected route={['all-user']}>
						<AllUsers />
					</AdminRoleProtected>
				),
			},

			// add user
			{
				path: '/admin/user/add-new',
				element: (
					<AdminRoleProtected route={['add-user']}>
						<AdminAddNewUser />
					</AdminRoleProtected>
				),
			},

			// active user
			{
				path: '/admin/active-users',
				element: (
					<AdminRoleProtected route={['active-user']}>
						<ActiveUsers />
					</AdminRoleProtected>
				),
			},

			// pending  user
			{
				path: '/admin/pending-users',
				element: (
					<AdminRoleProtected route={['pending-user']}>
						<PendingUsers />
					</AdminRoleProtected>
				),
			},

			// product category  create and get
			{
				path: '/admin/main-category',
				element: (
					<AdminRoleProtected route={['category']}>
						{/* <MainCategory /> */}
						<Category />
					</AdminRoleProtected>
				),
			},

			// product sub category create and get
			{
				path: '/admin/sub-category',
				element: (
					<AdminRoleProtected route={['sub-category']}>
						<SubCategory />
					</AdminRoleProtected>
				),
			},

			// brand create get edit
			{
				path: '/admin/brand-list',
				element: (
					<AdminRoleProtected route={['brand']}>
						<Brand />
					</AdminRoleProtected>
				),
			},

			// vendor product all
			{
				path: '/admin/vendor-product-list',
				element: (
					<AdminRoleProtected route={['all-products']}>
						<AllProductsVendor />
					</AdminRoleProtected>
				),
			},

			// vendor product active
			{
				path: '/admin/vendor-active-product',
				element: (
					<AdminRoleProtected route={['active-products']}>
						<ActiveProductsVendor />
					</AdminRoleProtected>
				),
			},

			// vendor product pending
			{
				path: '/admin/vendor-pending-product',
				element: (
					<AdminRoleProtected route={['pending-products']}>
						<PendingProductsVendor />
					</AdminRoleProtected>
				),
			},

			// vendor product edited
			{
				path: '/admin/vendor-edited-product',
				element: (
					<AdminRoleProtected route={['edit-products']}>
						<VendorEditedProductAdmin />
					</AdminRoleProtected>
				),
			},

			// vendor product all rejected
			{
				path: '/admin/vendor-rejected-product',
				element: (
					<AdminRoleProtected route={['rejected-product']}>
						<RejectedProductsVendor />
					</AdminRoleProtected>
				),
			},

			// edited like view
			{
				path: '/admin/product-view-edited/:id',
				element: <ProductAdd />,
			},
			// {
			// 	path: '/admin/product-view-edited/:id',
			// 	element: <VendorProductViewLikeEdit />,
			// },

			// affiliate product request
			{
				path: '/admin/affiliate-product-request',
				element: (
					<AdminRoleProtected route={['all-request']}>
						<AllRequestAffiliate />
					</AdminRoleProtected>
				),
			},

			// affiliate product request  active
			{
				path: '/admin/affiliate-active-request',
				element: (
					<AdminRoleProtected route={['active-request']}>
						<ActiveRequestAffiliate />
					</AdminRoleProtected>
				),
			},

			// affiliate product request pending
			{
				path: '/admin/affiliate-pending-request',
				element: (
					<AdminRoleProtected route={['pending-request']}>
						<PendingRequestAffiliate />
					</AdminRoleProtected>
				),
			},

			// affiliate product request rejected
			{
				path: '/admin/affiliate-rejected-request',
				element: (
					<AdminRoleProtected route={['rejected-request']}>
						<RejectedRequestAffiliate />
					</AdminRoleProtected>
				),
			},

			// order history all
			{
				path: '/admin/orders-history-all',
				element: (
					<AdminRoleProtected route={['all-order']}>
						<AdminOrderHistoryAll />
					</AdminRoleProtected>
				),
			},

			// order history hold
			{
				path: '/admin/orders-history-hold',
				element: (
					<AdminRoleProtected route={['order-hold']}>
						<AdminOrderHistoryHold />
					</AdminRoleProtected>
				),
			},

			// order history hold pending
			{
				path: '/admin/orders-history-pending',
				element: (
					<AdminRoleProtected route={['order-pending']}>
						<AdminOrderHistoryPending />
					</AdminRoleProtected>
				),
			},

			// order history received
			{
				path: '/admin/orders-history-received',
				element: (
					<AdminRoleProtected route={['order-received']}>
						<AdminOrderHistoryReceived />
					</AdminRoleProtected>
				),
			},

			// product processing
			{
				path: '/admin/orders-history-processing',
				element: (
					<AdminRoleProtected route={['order-processing']}>
						<AdminOrderHistoryProcessing />
					</AdminRoleProtected>
				),
			},
			// product ready
			{
				path: '/admin/orders-history-ready',
				element: (
					<AdminRoleProtected route={['order-ready']}>
						<AdminOrderHistoryReady />
					</AdminRoleProtected>
				),
			},
			// order history progress
			{
				path: '/admin/orders-history-progress',
				element: (
					<AdminRoleProtected route={['delivery-processing']}>
						<AdminOrderHistoryProgress />
					</AdminRoleProtected>
				),
			},

			// order history delivered
			{
				path: '/admin/orders-history-delivered',
				element: (
					<AdminRoleProtected route={['product-delivered']}>
						<AdminOrderHistoryDelivered />
					</AdminRoleProtected>
				),
			},
			// product return
			{
				path: '/admin/orders-history-return',
				element: (
					<AdminRoleProtected route={['order-return']}>
						<AdminOrderHistoryReturn />
					</AdminRoleProtected>
				),
			},

			// order history cancel
			{
				path: '/admin/orders-history-cancel',
				element: (
					<AdminRoleProtected route={['order-cancel']}>
						<AdminOrderHistoryCancel />
					</AdminRoleProtected>
				),
			},

			// order history view
			{
				path: '/admin/orders-history-view/:id',
				element: (
					<AdminRoleProtected
						route={[
							'all-order',
							'order-hold',
							'order-pending',
							'order-received',
							'delivery-processing',
							'product-delivered',
							'order-cancel',
						]}
					>
						<AdminOrderHistoryView />
					</AdminRoleProtected>
				),
			},

			// service category
			{
				path: '/admin/service-category',
				element: (
					<AdminRoleProtected route={['service-category']}>
						<ServiceCategory />
					</AdminRoleProtected>
				),
			},

			// service sub category
			{
				path: '/admin/service-sub-category',
				element: (
					<AdminRoleProtected route={['service-sub-category']}>
						<ServiceSubCategory />
					</AdminRoleProtected>
				),
			},

			// service manage
			{
				path: '/admin/service',
				element: (
					<AdminRoleProtected route={['manage-service']}>
						<AllService />
					</AdminRoleProtected>
				),
			},

			// service view
			{
				path: '/admin/service/:id',
				element: (
					<AdminRoleProtected route={['manage-service']}>
						<GetVendorSingleService />
					</AdminRoleProtected>
				),
			},

			// service order
			{
				path: '/admin/service-order',
				element: (
					<AdminRoleProtected route={['service-order']}>
						<MyServiceOrder />
					</AdminRoleProtected>
				),
			},

			// manage service order
			// {
			// 	path: '/admin/service-order/:id',
			// 	element: (
			// 		<AdminRoleProtected route={['service-order']}>
			// 			<ServiceView />
			// 		</AdminRoleProtected>
			// 	),
			// },

			// manage service order
			{
				path: '/admin/my-service-order/:id',
				element: (
					<AdminRoleProtected route={['service-order']}>
						<ServiceDeliveryView />
					</AdminRoleProtected>
				),
			},

			// advertise utility
			{
				path: '/admin/advertise-utility',
				element: (
					<AdminRoleProtected route={['advertise-utility']}>
						<AdvertiseUtility />
					</AdminRoleProtected>
				),
			},

			{
				path: '/admin/unit',
				element: (
					<AdminRoleProtected route={['advertise-utility']}>
						<Unit />
					</AdminRoleProtected>
				),
			},

			// advertise all
			{
				path: '/admin/advertise',
				element: (
					<AdminRoleProtected route={['all-advertiser']}>
						<AdvertiseAllAdmin />
					</AdminRoleProtected>
				),
			},

			// advertise view
			{
				path: '/admin/advertise-view/:id',
				element: (
					<AdminRoleProtected route={['all-advertiser']}>
						<AdvertiseViewAdmin />
					</AdminRoleProtected>
				),
			},

			// advertise edit
			{
				path: '/admin/advertise-update/:id',
				element: (
					<AdminRoleProtected route={['all-advertiser']}>
						<AdvertiseEditAdmin />
					</AdminRoleProtected>
				),
			},

			// support all
			{
				path: '/admin/all-support',
				element: (
					<AdminRoleProtected route={['support']}>
						<AdminAllSupport />
					</AdminRoleProtected>
				),
			},

			// support view
			// {
			// 	path: '/admin/single-support/:id',
			// 	element: (
			// 		<AdminRoleProtected route={['support']}>
			// 			<AdminSingleSupport />
			// 		</AdminRoleProtected>
			// 	),
			// },
			{
				path: '/admin/single-support/:id',
				element: <AdminSingleSupport />,
			},

			// support category
			{
				path: '/admin/support-cateogory',
				element: (
					<AdminRoleProtected route={['support-problem-topic']}>
						<SupportCategory />
					</AdminRoleProtected>
				),
			},

			// support topic
			{
				path: '/admin/support-problem-topic',
				element: (
					<AdminRoleProtected route={['support-problem-topic']}>
						<SupportSubCategory />
					</AdminRoleProtected>
				),
			},

			// subscription
			{
				path: '/admin/subscription',
				element: (
					<AdminRoleProtected route={['subscription']}>
						<GetSubscription />
					</AdminRoleProtected>
				),
			},

			// subscription 2 edit in one
			{
				path: '/admin/subscription/:id',
				element: (
					<AdminRoleProtected route={['subscription']}>
						<SubscriptionUpdate />
					</AdminRoleProtected>
				),
			},

			// coupon create
			{
				path: '/admin/create-coupon/:email',
				element: (
					<AdminRoleProtected route={['create-coupon', 'request-coupon']}>
						<CreateCoupon />
					</AdminRoleProtected>
				),
			},

			// coupon all
			{
				path: '/admin/all-coupon',
				element: (
					<AdminRoleProtected route={['active-coupon']}>
						<AdminCoupon />
					</AdminRoleProtected>
				),
			},

			// coupon edit
			{
				path: '/admin/edit-coupon/:id',
				element: (
					<AdminRoleProtected route={['active-coupon']}>
						<EditCoupon />
					</AdminRoleProtected>
				),
			},

			// coupon request
			{
				path: '/admin/all-request-coupon',
				element: (
					<AdminRoleProtected route={['active-coupon']}>
						<GetUserRequestCoupon />
					</AdminRoleProtected>
				),
			},

			// coupon rejected
			{
				path: '/admin/all-request-rejected-coupon',
				element: (
					<AdminRoleProtected route={['rejected-coupon']}>
						<GetUserRequestRejectedCoupon />
					</AdminRoleProtected>
				),
			},

			// membership
			{
				path: '/admin/vendor-affiliate-membership',
				element: (
					<AdminRoleProtected route={['membership']}>
						<GetMembership />
					</AdminRoleProtected>
				),
			},

			// withdraw
			{
				path: '/admin/users-withdraw',
				element: (
					<AdminRoleProtected route={['withdraw']}>
						<AdminWithdraw />
					</AdminRoleProtected>
				),
			},

			// setting
			{
				path: '/admin/setting',
				element: (
					<AdminRoleProtected route={['setting']}>
						<AdminSetting />
					</AdminRoleProtected>
				),
			},

			// home content
			{
				path: '/admin/content/service',
				element: (
					<AdminRoleProtected route={['home-service']}>
						<HomeContentService />
					</AdminRoleProtected>
				),
			},

			{
				path: '/admin/organization',
				element: (
					<AdminRoleProtected route={['organization']}>
						<HomeContentOrganization />
					</AdminRoleProtected>
				),
			},
			{
				path: '/admin/it-service',
				element: (
					<AdminRoleProtected route={['it-service']}>
						<HomeContentItService />
					</AdminRoleProtected>
				),
			},

			{
				path: '/admin/organization-two',
				element: (
					<AdminRoleProtected route={['organization-two']}>
						<HomeContentOrganizationTwo />
					</AdminRoleProtected>
				),
			},

			{
				path: '/admin/partner',
				element: (
					<AdminRoleProtected route={['partner']}>
						<HomeContentPartner />
					</AdminRoleProtected>
				),
			},

			{
				path: '/admin/footer',
				element: (
					<AdminRoleProtected route={['content']}>
						<HomeContentFooterMedia />
					</AdminRoleProtected>
				),
			},
			{
				path: '/admin/create-footer',
				element: (
					<AdminRoleProtected route={['content']}>
						<CreateFooter />
					</AdminRoleProtected>
				),
			},
			{
				path: '/admin/update-home-content',
				element: (
					<AdminRoleProtected route={['home-update-content']}>
						<HomeContent />
					</AdminRoleProtected>
				),
			},

			// about content
			{
				path: '/admin/update-companions',
				element: (
					<AdminRoleProtected route={['companions']}>
						<AboutContentCompanions />
					</AdminRoleProtected>
				),
			},

			{
				path: '/admin/missions',
				element: (
					<AdminRoleProtected route={['mission']}>
						<AboutContentMission />
					</AdminRoleProtected>
				),
			},

			{
				path: '/admin/testimonial',
				element: (
					<AdminRoleProtected route={['testimonial']}>
						<AboutContentTestimonial />
					</AdminRoleProtected>
				),
			},

			{
				path: '/admin/members',
				element: (
					<AdminRoleProtected route={['members']}>
						<AboutContentMember />
					</AdminRoleProtected>
				),
			},

			{
				path: '/admin/update-about-content',
				element: (
					<AdminRoleProtected route={['abount-update-content']}>
						<AboutUpdateContent />
					</AdminRoleProtected>
				),
			},

			// general content
			{
				path: '/admin/update-general-content',
				element: (
					<AdminRoleProtected route={['general-update-content']}>
						<GeneralContentUpdate />
					</AdminRoleProtected>
				),
			},

			// advertise content
			{
				path: '/admin/faq',
				element: (
					<AdminRoleProtected route={['faq']}>
						<AdvertiserContentFaq />
					</AdminRoleProtected>
				),
			},

			{
				path: '/admin/update-advertise-content',
				element: (
					<AdminRoleProtected route={['advertise-update-content']}>
						<AdvertiseContentUpdate />
					</AdminRoleProtected>
				),
			},
			{
				path: '/admin/update-service-content',
				element: (
					<AdminRoleProtected route={['service-update-content']}>
						<ServiceContentUpdate />
					</AdminRoleProtected>
				),
			},
			// role
			{
				path: '/admin-dashboard/role-permission',
				element: (
					<AdminRoleProtected route={['role']}>
						<RolePermission />
					</AdminRoleProtected>
				),
			},

			// support assign
			{
				path: '/admin/support-assign',
				element: <AdminAllSupport />,
			},

			{
				path: '/admin/vendor-edited-product/:id',
				element: <VendorEditedProductAdminView />,
			},

			{
				path: '/admin/product-view/:id',
				element: <VendorProductView />,
			},

			{
				path: '/admin/affiliate-view-request-product/:id',
				element: <ViewRequestProduct />,
			},

			{
				path: '/admin/profile',
				element: <AdminProfile />,
			},

			{
				path: '/admin/advertiser-content/delivery/:id',
				element: <AdvertiserDelivary />,
			},
			{
				path: '/admin/contact-message',
				element: (
					<AdminRoleProtected route={['users-response']}>
						<GetContactMessage />
					</AdminRoleProtected>
				),
			},

			{
				path: '/admin-dashboard/invoice',
				element: <Invoice />,
			},

			// just dummy profile view
			{
				path: '/admin/view/:id',
				element: (
					<AdminRoleProtected route={['dashboard']}>
						<View />
					</AdminRoleProtected>
				),
			},
		],
	},

	// admin  sing in sing up
	{
		path: '/sign-in',
		element: <SignInV2 />,
	},
	{
		path: '/sign-up',
		element: <RegisterV2 />,
	},

	// vendor routes

	{
		path: '/',
		element: (
			<React.Suspense
				fallback={
					<div className="d-flex justify-content-center align-items-center h-100 w-100">
						<ThreeDots />
					</div>
				}
			>
				<PrivateRoutes>
					<CheckPermission roleID="2">
						<VendorLayout />
					</CheckPermission>
				</PrivateRoutes>
			</React.Suspense>
		),
		children: [
			{
				path: vendorRoutes.dashboard,
				element: <VendorsDashboard />,
			},

			// add product
			{
				path: vendorRoutes.productAdd + ':id',
				element: (
					<VendorRoleProtected route={['add_product']}>
						<ProductAdd />
					</VendorRoleProtected>
				),
			},

			// all product
			{
				path: vendorRoutes.allProduct,
				element: (
					<VendorRoleProtected route={['all_product']}>
						{/* <VendorProductList /> */}
						<ProductListV2 />
					</VendorRoleProtected>
				),
			},

			// active product
			{
				path: vendorRoutes.activeProduct,
				element: (
					<VendorRoleProtected route={['active_product']}>
						<VendorActiveProduct />
					</VendorRoleProtected>
				),
			},

			// pending product
			{
				path: vendorRoutes.pendingProduct,
				element: (
					<VendorRoleProtected route={['pending_product']}>
						<VendorPendingProduct />
					</VendorRoleProtected>
				),
			},

			// edited product
			{
				path: vendorRoutes.editedProduct,
				element: (
					<VendorRoleProtected route={['edit_product']}>
						<VendorEditedProduct />
					</VendorRoleProtected>
				),
			},

			// rejected product
			{
				path: vendorRoutes.rejectedProduct,
				element: (
					<VendorRoleProtected route={['reject_product']}>
						<VendorRejectedProduct />
					</VendorRoleProtected>
				),
			},

			// damage product
			{
				path: vendorRoutes.damageProduct,
				element: (
					<VendorRoleProtected route={['create_damage']}>
						<Damage />
					</VendorRoleProtected>
				),
			},

			// damage product
			{
				path: vendorRoutes.damageProductList,
				element: (
					<VendorRoleProtected route={['damage_list']}>
						<DamageLIst />
					</VendorRoleProtected>
				),
			},

			{
				path: '/vendors-dashboard/product-view-edited/:id',
				element: (
					<VendorRoleProtected route={['edit_product']}>
						<VendorProductEditedView />
					</VendorRoleProtected>
				),
			},

			{
				path: '/vendors-dashboard/order-exchange/:id',
				element: (
					<VendorRoleProtected route={['all_product']}>
						<OrderExchange />
					</VendorRoleProtected>
				),
			},
			{
				path: '/vendors-dashboard/product-view/:id',
				element: (
					<VendorRoleProtected route={['all_product']}>
						<VendorProductPreview />
					</VendorRoleProtected>
				),
			},

			// deliveryCharge
			{
				path: vendorRoutes.deliveryCharge,
				element: (
					<VendorRoleProtected route={['delivery_charge']}>
						<DeliveryCharge />
					</VendorRoleProtected>
				),
			},
			// delivery area
			{
				path: vendorRoutes.deliveryArea,
				element: (
					<VendorRoleProtected route={['delivery_area']}>
						<DeliveryArea />
					</VendorRoleProtected>
				),
			},
			// pickup area
			{
				path: vendorRoutes.pickupArea,
				element: (
					<VendorRoleProtected route={['pickup_area']}>
						<PickupArea />
					</VendorRoleProtected>
				),
			},
			// warehouse
			{
				path: vendorRoutes.warehouse,
				element: (
					<VendorRoleProtected route={['warehouse']}>
						<WareHouse />
					</VendorRoleProtected>
				),
			},

			// unit
			{
				path: vendorRoutes.unit,
				element: (
					<VendorRoleProtected route={['unit']}>
						<Unit />
					</VendorRoleProtected>
				),
			},

			// utility
			{
				path: vendorRoutes.utility,
				element: (
					<VendorRoleProtected route={['color']}>
						<Utility />
					</VendorRoleProtected>
				),
			},

			// variation
			{
				path: vendorRoutes.variation,
				element: (
					<VendorRoleProtected route={['variation']}>
						<Variation />
					</VendorRoleProtected>
				),
			},

			{
				path: vendorRoutes.affiliateAllRequest,
				element: (
					<VendorRoleProtected route={['all_request']}>
						<AffiliateAllRequest />
					</VendorRoleProtected>
				),
			},
			{
				path: vendorRoutes.affiliateActiveRequest,
				element: (
					<VendorRoleProtected route={['active_request']}>
						<AffiliateActiveRequest />
					</VendorRoleProtected>
				),
			},
			{
				path: vendorRoutes.affiliatePendingRequest,
				element: (
					<VendorRoleProtected route={['pending_request']}>
						<AffiliatePendingRequest />
					</VendorRoleProtected>
				),
			},
			{
				path: vendorRoutes.affiliateRejectedRequest,
				element: (
					<VendorRoleProtected route={['reject_request']}>
						<AffiliateRejectedRequest />
					</VendorRoleProtected>
				),
			},
			{
				path: '/vendors-dashboard/affiliate-expired-products',
				element: (
					<VendorRoleProtected route={['expired_request']}>
						<AffiliateExpiredProducts />
					</VendorRoleProtected>
				),
			},

			{
				path: '/vendors-dashboard/product-view-requested/:id',
				element: (
					<VendorRoleProtected route={['all_request']}>
						<AffiliateRequestView />
					</VendorRoleProtected>
				),
			},
			// orders
			{
				path: vendorRoutes.orderAll,
				element: (
					<VendorRoleProtected route={['all_order']}>
						<VendorOrderHistoryAll />
					</VendorRoleProtected>
				),
			},

			// new order
			{
				path: vendorRoutes.orderNew,
				element: (
					<VendorRoleProtected route={['add_order']}>
						<NewOrder />
					</VendorRoleProtected>
				),
			},
			{
				path: vendorRoutes.orderNew + '/:id',
				element: (
					<VendorRoleProtected route={['add_order']}>
						<NewOrderInvoice />
					</VendorRoleProtected>
				),
			},
			{
				path: '/vendors-dashboard/new-order-return/:id',
				element: (
					<VendorRoleProtected route={['add_order']}>
						<NewOrderReturn />
					</VendorRoleProtected>
				),
			},

			// order hold
			{
				path: vendorRoutes.orderHold,
				element: (
					<VendorRoleProtected route={['hold_order']}>
						<VendorOrderHistoryHold />
					</VendorRoleProtected>
				),
			},

			// order  pending
			{
				path: vendorRoutes.orderPending,
				element: (
					<VendorRoleProtected route={['pending_order']}>
						<VendorOrderHistoryPending />
					</VendorRoleProtected>
				),
			},

			// order  received
			{
				path: vendorRoutes.orderReceived,
				element: (
					<VendorRoleProtected route={['receive_order']}>
						<VendorOrderHistoryReceived />
					</VendorRoleProtected>
				),
			},

			// product ready
			{
				path: vendorRoutes.orderReady,
				element: (
					<VendorRoleProtected route={['order_ready']}>
						<VendorOrderHistoryReady />
					</VendorRoleProtected>
				),
			},
			// product ready
			{
				path: vendorRoutes.orderProcessing,
				element: (
					<VendorRoleProtected route={['order_processing']}>
						<VendorOrderHistoryProcessing />
					</VendorRoleProtected>
				),
			},
			// order progress
			{
				path: vendorRoutes.orderProgress,
				element: (
					<VendorRoleProtected route={['delivery_processing']}>
						<VendorOrderHistoryProgress />
					</VendorRoleProtected>
				),
			},

			// order delivered
			{
				path: vendorRoutes.orderDelivered,
				element: (
					<VendorRoleProtected route={['delivery_order']}>
						<VendorOrderHistoryDelivered />
					</VendorRoleProtected>
				),
			},

			// order Return
			{
				path: vendorRoutes.orderReturn,
				element: (
					<VendorRoleProtected route={['order_return']}>
						<VendorOrderHistoryReturn />
					</VendorRoleProtected>
				),
			},

			// order cancel
			{
				path: vendorRoutes.orderCancel,
				element: (
					<VendorRoleProtected route={['cancel_order']}>
						<VendorOrderHistoryCancel />
					</VendorRoleProtected>
				),
			},

			{
				path: '/vendors-dashboard/orders-history-view/:id',
				element: (
					<VendorRoleProtected route={['all_order']}>
						<VendorOrderHistoryView />
					</VendorRoleProtected>
				),
			},

			// sales
			// customer
			{
				path: vendorRoutes.customer,
				element: (
					<VendorRoleProtected route={['customer']}>
						<Customer />
					</VendorRoleProtected>
				),
			},

			// pos sales
			{
				path: vendorRoutes.posSales,
				element: (
					<VendorRoleProtected route={['add_pos_sale']}>
						<PosSales />
					</VendorRoleProtected>
				),
			},

			// manage pos sales
			{
				path: vendorRoutes.posManageSales,
				element: (
					<VendorRoleProtected route={['all_pos_sale']}>
						<ManageSales />
					</VendorRoleProtected>
				),
			},

			// pos sales history
			{
				path: vendorRoutes.posSalesHistory,
				element: (
					<VendorRoleProtected route={['payment_history_pos_sale']}>
						<SalesHistory />
					</VendorRoleProtected>
				),
			},

			// supplier
			{
				path: vendorRoutes.supplier,
				element: (
					<VendorRoleProtected route={['supplier']}>
						<Supplier />
					</VendorRoleProtected>
				),
			},

			// purchase
			{
				path: vendorRoutes.purchase,
				element: (
					<VendorRoleProtected route={['add_purchase']}>
						<Purchase />
					</VendorRoleProtected>
				),
			},

			// manage purchase
			{
				path: vendorRoutes.managePurchase,
				element: (
					<VendorRoleProtected route={['all_purchase']}>
						<ManagePurchase />
					</VendorRoleProtected>
				),
			},

			// payment history
			{
				path: vendorRoutes.paymentHistory,
				element: (
					<VendorRoleProtected route={['payment_history_purchase']}>
						<PurchaseHistory />
					</VendorRoleProtected>
				),
			},

			// barcode generate
			{
				path: vendorRoutes.barcodeGenerate,
				element: (
					<VendorRoleProtected route={['barcode_generate']}>
						<PosSalesBarcodeGenerator />
					</VendorRoleProtected>
				),
			},

			// barcode manage
			{
				path: vendorRoutes.barcodeManage,
				element: (
					<VendorRoleProtected route={['barcode_manage']}>
						<BarCodeLists />
					</VendorRoleProtected>
				),
			},

			// payment methods
			{
				path: vendorRoutes.paymentMethods,
				element: (
					<VendorRoleProtected route={['payment_method']}>
						<PaymentMethods />
					</VendorRoleProtected>
				),
			},

			// Delivery Company
			{
				path: vendorRoutes.deliveryCompany,
				element: (
					<VendorRoleProtected route={['delivery_company']}>
						<DeliveryCompany />
					</VendorRoleProtected>
				),
			},

			// order source
			{
				path: vendorRoutes.orderSource,
				element: (
					<VendorRoleProtected route={['source']}>
						<OrderSource />
					</VendorRoleProtected>
				),
			},

			// invoice generate
			{
				path: vendorRoutes.invoiceGenerate,
				element: (
					<VendorRoleProtected route={['invoice_generate']}>
						<InvoiceGenerate />
					</VendorRoleProtected>
				),
			},

			// purchase return
			{
				path: vendorRoutes.purchaseReturn,
				element: (
					<VendorRoleProtected route={['purchase_return']}>
						<ReturnList />
					</VendorRoleProtected>
				),
			},

			// sales return
			{
				path: vendorRoutes.saleReturn,
				element: (
					<VendorRoleProtected route={['sale_return']}>
						<ReturnSales />
					</VendorRoleProtected>
				),
			},

			// add wastage
			{
				path: vendorRoutes.addWastage,
				element: (
					<VendorRoleProtected route={['add_wastage']}>
						<AddWastage />
					</VendorRoleProtected>
				),
			},

			// wastage list and return
			{
				path: vendorRoutes.wastageList + ':id',
				element: (
					<VendorRoleProtected route={['all_wastage']}>
						<Wastage />
					</VendorRoleProtected>
				),
			},

			// report
			// stoke report
			{
				path: vendorRoutes.stokeReport,
				element: (
					<VendorRoleProtected route={['stock_report']}>
						<StockReport />
					</VendorRoleProtected>
				),
			},

			// sales report
			{
				path: vendorRoutes.salesReport,
				element: (
					<VendorRoleProtected route={['sales_report']}>
						<SalesReport />
					</VendorRoleProtected>
				),
			},

			// dur sales report
			{
				path: vendorRoutes.dueSalesReport,
				element: (
					<VendorRoleProtected route={['due_sales_report']}>
						<DueSalesReport />
					</VendorRoleProtected>
				),
			},

			// purchase report
			{
				path: vendorRoutes.purchaseReport,
				element: (
					<VendorRoleProtected route={['purchase_report']}>
						<PurchaseReport />
					</VendorRoleProtected>
				),
			},

			// warehouse report
			{
				path: vendorRoutes.warehouseReport,
				element: (
					<VendorRoleProtected route={['warehouse_report']}>
						<WarehouseReport />
					</VendorRoleProtected>
				),
			},
			// stock_shortage_report report
			{
				path: vendorRoutes.stockShortage,
				element: (
					<VendorRoleProtected route={['stock_shortage_report']}>
						<StockShortageReport />
					</VendorRoleProtected>
				),
			},
			// topRepeatCustomer report
			{
				path: vendorRoutes.topRepeatCustomer,
				element: (
					<VendorRoleProtected route={['top_repeat_customer']}>
						<TopRepeatCustomer />
					</VendorRoleProtected>
				),
			},

			// SalesReportDaily report
			{
				path: vendorRoutes.salesReportDaily,
				element: (
					<VendorRoleProtected route={['sales_report_daily']}>
						<SalesReportDaily />
					</VendorRoleProtected>
				),
			},

			// service and order
			{
				path: vendorRoutes.allService,
				element: (
					<VendorRoleProtected route={['all_service']}>
						<AllService />
					</VendorRoleProtected>
				),
			},
			{
				path: vendorRoutes.createService,
				element: (
					<VendorRoleProtected route={['create_service']}>
						<ServiceCreate />
					</VendorRoleProtected>
				),
			},
			{
				path: '/vendors-dashboard/update-service/:id',
				element: (
					<VendorRoleProtected route={['all_service']}>
						<UpdateService />
					</VendorRoleProtected>
				),
			},
			{
				path: vendorRoutes.orderService,
				element: (
					<VendorRoleProtected route={['service_order']}>
						<UsersServiceOrderManage />
					</VendorRoleProtected>
				),
			},
			{
				path: '/vendors-dashboard/service-order/:id',
				element: (
					<VendorRoleProtected route={['service_order']}>
						<ServiceViewVendor />
					</VendorRoleProtected>
				),
			},

			// coupon
			{
				path: vendorRoutes.coupon,
				element: (
					<VendorRoleProtected route={['coupon']}>
						<GetVendorCoupon />
					</VendorRoleProtected>
				),
			},

			// membership
			{
				path: vendorRoutes.membership,
				element: (
					<VendorRoleProtected route={['membership']}>
						<Renew />
					</VendorRoleProtected>
				),
			},

			// advertise
			{
				path: vendorRoutes.advertise,
				element: (
					<VendorRoleProtected route={['advertiser']}>
						<AdvertiseAllVendor />
					</VendorRoleProtected>
				),
			},

			// advertise view
			{
				path: '/vendors-dashboard/advertise-view/:id',
				element: (
					<VendorRoleProtected route={['advertiser']}>
						<AdvertiseViewVendor />
					</VendorRoleProtected>
				),
			},

			// my-service-order
			{
				path: vendorRoutes.myServiceOrder,
				element: (
					<VendorRoleProtected route={['all_service_order']}>
						<MyServiceOrderVendor />
					</VendorRoleProtected>
				),
			},

			// history
			{
				path: vendorRoutes.history,
				element: (
					<VendorRoleProtected route={['recharge_history']}>
						<GetVendorHistory />
					</VendorRoleProtected>
				),
			},

			// Withdraw
			{
				path: vendorRoutes.withdraw,
				element: (
					<VendorRoleProtected route={['withdraw']}>
						<VendorWithdraw />
					</VendorRoleProtected>
				),
			},

			// recharge
			{
				path: vendorRoutes.recharge,
				element: (
					<VendorRoleProtected route={['recharge']}>
						<VendorRecharge />
					</VendorRoleProtected>
				),
			},

			// chat
			{
				path: vendorRoutes.chat,
				element: (
					<VendorRoleProtected route={['chat']}>
						<Chat />
					</VendorRoleProtected>
				),
			},

			// employee
			{
				path: vendorRoutes.employee,
				element: (
					<VendorRoleProtected route={['employee']}>
						<EmployeePErmission />
					</VendorRoleProtected>
				),
			},

			// create support
			{
				path: vendorRoutes.createSupport,
				element: (
					<VendorRoleProtected route={['create_support']}>
						<CreateSupport />
					</VendorRoleProtected>
				),
			},

			// all support ticket
			{
				path: vendorRoutes.allSupport,
				element: (
					<VendorRoleProtected route={['all_support']}>
						<AllSupport />
					</VendorRoleProtected>
				),
			},

			// view support
			{
				path: '/vendors-dashboard/single-support-ticket/:id',
				element: (
					<VendorRoleProtected route={['all_support', 'create_support']}>
						<VendorSingleSupport />
					</VendorRoleProtected>
				),
			},

			{
				path: '/vendors-dashboard/balance-deposit',
				element: (
					<VendorRoleProtected route={['protected_route']}>
						<DepositBalance />
					</VendorRoleProtected>
				),
			},
			{
				path: '/vendors-dashboard/all-deposit',
				element: (
					<VendorRoleProtected route={['protected_route']}>
						<AllDeposit />
					</VendorRoleProtected>
				),
			},
			{
				path: '/vendors-dashboard/cancel-deposit',
				element: (
					<VendorRoleProtected route={['protected_route']}>
						<CancelDeposit />
					</VendorRoleProtected>
				),
			},
			{
				path: '/vendors-dashboard/accept-deposit',
				element: (
					<VendorRoleProtected route={['protected_route']}>
						<AcceptDeposit />
					</VendorRoleProtected>
				),
			},

			{
				path: '/vendors-dashboard/pending-deposit',
				element: (
					<VendorRoleProtected route={['protected_route']}>
						<PendingDeposit />
					</VendorRoleProtected>
				),
			},
			{
				path: '/vendors-dashboard/profile',
				element: (
					<VendorRoleProtected route={['protected_route']}>
						<VendorProfile />
					</VendorRoleProtected>
				),
			},

			{
				path: '/vendors-dashboard/:from/-return/:id',
				element: (
					<VendorRoleProtected route={['protected_route']}>
						<SalesReturn />
					</VendorRoleProtected>
				),
			},

			{
				path: '/vendors-dashboard/renew/checkout/:id',
				element: (
					<VendorRoleProtected route={['protected_route']}>
						<VendorCheckout />
					</VendorRoleProtected>
				),
			},

			{
				path: '/vendors-dashboard/add-purchase',
				element: (
					<VendorRoleProtected route={['protected_route']}>
						<AddPurchase />
					</VendorRoleProtected>
				),
			},

			{
				path: '/vendors-dashboard/my-service-order/:id',
				element: (
					<VendorRoleProtected route={['protected_route']}>
						<ServiceDeliveryView />
					</VendorRoleProtected>
				),
			},
			{
				path: '/vendor-dashboard/my-service/delivery/:id',
				element: (
					<VendorRoleProtected route={['protected_route']}>
						<VendorServiceDelivery />
					</VendorRoleProtected>
				),
			},
			{
				path: '/vendors-dashboard/service-order/delivery/:id',
				element: (
					<VendorRoleProtected route={['protected_route']}>
						<ServiceDeliveryVendor />
					</VendorRoleProtected>
				),
			},
			{
				path: '/vendor-dashboard/request-coupon',
				element: (
					<VendorRoleProtected route={['protected_route']}>
						<UserCoponRequest />
					</VendorRoleProtected>
				),
			},
			{
				path: '/vendors-dashboard/invoice/:id',
				element: (
					<VendorRoleProtected route={['protected_route']}>
						<Invoice />
					</VendorRoleProtected>
				),
			},
			{
				path: '/vendors-dashboard/purchase-invoice/:id',
				element: (
					<VendorRoleProtected route={['protected_route']}>
						<PurchaseInvoice />
					</VendorRoleProtected>
				),
			},

			{
				path: '/vendors-dashboard/:from/-invoice/:id',
				element: (
					<VendorRoleProtected route={['protected_route']}>
						<SalesInvoice />
					</VendorRoleProtected>
				),
			},

			{
				path: '/vendors-dashboard/pos-sales-return/:id',
				element: (
					<VendorRoleProtected route={['protected_route']}>
						<PosSalesReturn />
					</VendorRoleProtected>
				),
			},

			{
				path: '/vendors-dashboard/employee',
				element: (
					<VendorRoleProtected route={['employee']}>
						<Employee />
					</VendorRoleProtected>
				),
			},
			{
				path: '/vendors-dashboard/employee-create/:id',
				element: (
					<VendorRoleProtected route={['employee']}>
						<CreateEmployee />
					</VendorRoleProtected>
				),
			},
		],
	},

	// affiliates layout
	{
		path: '/',
		element: (
			<React.Suspense
				fallback={
					<div className="d-flex justify-content-center align-items-center h-100 w-100">
						<ThreeDots />
					</div>
				}
			>
				<PrivateRoutes>
					<CheckPermission roleID="3">
						<AffiliatesLayout />
					</CheckPermission>
				</PrivateRoutes>
			</React.Suspense>
		),
		children: [
			{
				path: '/affiliates-dashboard',
				element: <AffiliatesDashboard />,
			},
			{
				path: '/affiliates-dashboard/all-product',
				element: <AllProductAffiliate />,
			},
			{
				path: '/affiliates-dashboard/product-view/:id',
				element: <ProductDetailsAll />,
			},
			{
				path: '/affiliates-dashboard/product-view-active/:id',
				element: <ActiveDetails />,
			},
			{
				path: '/affiliates-dashboard/pending-product-details/:id',
				element: <PendingDetails />,
			},
			{
				path: '/affiliates-dashboard/active-product',
				element: <ActiveProductAffiliate />,
			},
			{
				path: '/affiliates-dashboard/expired-product',
				element: <ExpiredProductAffiliate />,
			},
			{
				path: '/affiliates-dashboard/pending-product',
				element: <PendingProductAffiliate />,
			},
			{
				path: '/affiliates-dashboard/rejected-product',
				element: <RejectedProductAffiliate />,
			},

			{
				path: '/affiliates-dashboard/orders-history-all',
				element: <OrderHistoryAll />,
			},
			{
				path: '/affiliates-dashboard/orders-history-pending',
				element: <OrderHistoryPending />,
			},
			{
				path: '/affiliates-dashboard/orders-history-progress',
				element: <OrderHistoryProgress />,
			},
			{
				path: '/affiliates-dashboard/orders-history-received',
				element: <OrderHistoryRecived />,
			},
			{
				path: '/affiliates-dashboard/orders-history-delivered',
				element: <OrderHistoryDelivered />,
			},
			{
				path: '/affiliates-dashboard/orders-history-cancel',
				element: <OrderHistoryCancel />,
			},
			{
				path: '/affiliates-dashboard/orders-history-hold',
				element: <OrderHistoryHold />,
			},
			{
				path: '/affiliates-dashboard/orders-history-ready',
				element: <OrderHistoryReady />,
			},
			{
				path: '/affiliates-dashboard/orders-history-processing',
				element: <OrderHistoryProcessing />,
			},
			{
				path: '/affiliates-dashboard/orders-history-return',
				element: <OrderHistoryReturn />,
			},
			{
				path: '/affiliates-dashboard/orders-history-view/:id',
				element: <AffiliateOrderHistoryView />,
			},
			{
				path: '/affiliates-dashboard/available-balance',
				element: <AffiliateAvailableIncome />,
			},
			{
				path: '/affiliates-dashboard/pending-balance',
				element: <AffiliatePendingIncome />,
			},
			{
				path: '/affiliates-dashboard/withdraw-balance',
				element: <AffiliateWithdrawIncome />,
			},
			{
				path: '/affiliates-dashboard/balance-history',
				element: <AffiliateBalanceHistory />,
			},

			{
				path: '/affiliates-dashboard/add-to-cart',
				element: <AddToCart />,
			},
			{
				path: '/affiliates-dashboard/checkout/:id',
				element: <Checkout />,
			},
			{
				path: '/affiliates-dashboard/profile',
				element: <AffiliatesProfile />,
			},
			{
				path: '/affiliates-dashboard/create-support',
				element: <AffiliatesCreateSupport />,
			},
			{
				path: '/affiliates-dashboard/all-support',
				element: <AffiliatesAllSupport />,
			},
			{
				path: '/affiliates-dashboard/single-support/:id',
				element: <AffiliatesSingleSupport />,
			},
			{
				path: '/affiliates-dashboard/coupon',
				element: <GetAffiliatesCoupon />,
			},
			{
				path: '/affiliates-dashboard/all-service',
				element: <AllService />,
			},
			{
				path: '/affiliates-dashboard/create-service',
				element: <ServiceCreate />,
			},
			{
				path: '/affiliates-dashboard/update-service/:id',
				element: <UpdateService />,
			},
			{
				path: '/affiliates-dashboard/renew',
				element: <AffiRenew />,
			},
			{
				path: '/affiliates-dashboard/renew/checkout/:id',
				element: <AffiCheckout />,
			},
			{
				path: '/affiliates-dashboard/advertise',
				element: <AdvertiseAllAffiliate />,
			},
			{
				path: '/affiliates-dashboard/advertise-view/:id',
				element: <AdvertiseViewAffiliate />,
			},
			{
				path: '/affiliates-dashboard/service-order',
				element: <ServiceOrderAffiliate />,
			},
			{
				path: '/affiliates-dashboard/service-order/:id',
				element: <ServiceView />,
			},
			// {
			// 	path: '/affiliates-dashboard/service-order/:id',
			// 	element: <GetAffiSingleService />,
			// },
			{
				path: '/affiliates-dashboard/service-order/delivery/:id',
				element: <ServiceDelivery />,
			},
			{
				path: '/affiliates-dashboard/history',
				element: <GetAffiliateHistory />,
			},
			// {
			// 	path: '/affiliates-dashboard/my-service-order',
			// 	element: <GetAffiliateOrder />,
			// },
			{
				path: '/affiliates-dashboard/my-service-order',
				element: <MyServiceOrderAffiliate />,
			},
			{
				path: '/affiliates-dashboard/pending-order',
				element: <AffiPendingOrder />,
			},
			{
				path: '/affiliates-dashboard/progress-order',
				element: <AffiProgressOrder />,
			},
			{
				path: '/affiliates-dashboard/hold-order',
				element: <AffiHoldOrder />,
			},
			{
				path: '/affiliates-dashboard/my-service-order/:id',
				element: <ServiceDeliveryView />,
			},
			{
				path: '/affiliate-dashboard/recharge',
				element: <AffiliateRecharge />,
			},
			{
				path: '/affiliates-dashboard/request-coupon',
				element: <UserCoponRequest />,
			},
			{
				path: '/affiliates-dashboard/invoice/:id',
				element: <Invoice />,
			},
			{
				path: '/affiliates-dashboard/chat',
				element: <Chat />,
			},
			{
				path: '/affiliates-dashboard/product/:from/:id',
				element: <ProductView2 />,
			},
		],
	},

	{
		path: '/',
		element: (
			<React.Suspense
				fallback={
					<div className="d-flex justify-content-center align-items-center h-100 w-100">
						<ThreeDots />
					</div>
				}
			>
				<PrivateRoutes>
					<CheckPermission roleID="4">
						<UserLayout />
					</CheckPermission>
				</PrivateRoutes>
			</React.Suspense>
		),
		children: [
			{
				path: '/users-dashboard',
				element: <UserDashboard />,
			},
			{
				path: '/users-dashboard/switch-subscription/:name',
				element: <SwitchOption />,
			},
			{
				path: 'users-dashboard/renew/checkout/:id',
				element: <SwitchCheckout />,
			},
			{
				path: '/users-dashboard/all-support',
				element: <UserAllSupport />,
			},
			{
				path: '/users-dashboard/support/:id',
				element: <UserSingleSupport />,
			},
			{
				path: '/users-dashboard/create-ticket',
				element: <CreateTicket />,
			},
			{
				path: '/users-dashboard/my-service-order',
				element: <MyServiceOrderUser />,
			},
			{
				path: '/users-dashboard/pending-orders',
				element: <PendingOrder />,
			},
			{
				path: '/users-dashboard/progress-orders',
				element: <ProgressOrder />,
			},
			{
				path: '/users-dashboard/hold-orders',
				element: <HoldOrder />,
			},
			{
				path: '/users-dashboard/cancel-orders',
				element: <CancelOrder />,
			},
			{
				path: '/users-dashboard/success-orders',
				element: <CancelOrder />,
			},
			{
				path: '/users-dashboard/my-service-order/:id',
				element: <ServiceDeliveryView />,
			},
			{
				path: '/users-dashboard/advertise',
				element: <AdvertiseAllUser />,
			},
			{
				path: '/users-dashboard/advertise-view/:id',
				element: <AdvertiseViewUser />,
			},
			{
				path: '/users-dashboard/history',
				element: <GetUserHistory />,
			},
			{
				path: '/users-dashboard/recharge',
				element: <UserRecharge />,
			},
			{
				path: '/users-dashboard/profile',
				element: <UserProfile />,
			},
			{
				path: '/users-dashboard/withdraw',
				element: <UsersWithdraw />,
			},
			{
				path: '/users-dashboard/withdraw-history',
				element: <GetAllWithdrawHistory />,
			},
			{
				path: '/users-dashboard/pending-withdraw',
				element: <GetPendingWithdraw />,
			},
			{
				path: '/users-dashboard/success-withdraw',
				element: <GetSuccessWithdraw />,
			},
			{
				path: '/users-dashboard/invoice/:id',
				element: <Invoice />,
			},
		],
	},

	{
		path: '*',
		element: <NotFound />,
	},
]);

export default route;
