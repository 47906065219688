// edit pen img
import edit from './icons/edit.svg';
import print from './icons/print.svg';
import preview768 from './img/preview-765-850.jpg';
import logo from './img/logo.svg';
import plusRound from './icons/plus.svg';
import submitIcon from './icons/submitIcon.svg';
import user from './icons/user.svg';
import list from './icons/list.svg';
import listActive from './icons/list-active.svg';
import grid from './icons/grid.svg';
import gridActive from './icons/grid-active.svg';
import message from './icons/message.svg';
import messageDark from './icons/messageDark.svg';
import percentage from './icons/percentage.svg';
import couponActive from './icons/coupon-active.svg';

export const img = {
	edit,
	print,
	logo,
	preview768,
	plusRound,
	submitIcon,
	user,
};

export const icon = {
	list: [list, listActive],
	grid: [grid, gridActive],
	message,
	messageDark,
	percentage,
	couponActive,
};
