import { Link } from 'react-router-dom';
import { CopyClipboardHandler, countWord, time } from '../action/actions';
import demoIcon from '../../assets/use-image-this-app/icon.jpg';
import { MdContentCopy } from 'react-icons/md';
import { RiCheckboxMultipleFill } from 'react-icons/ri';
import { img } from '../../assets/sos/image';

export const DemoIcon = demoIcon;

export const NoDataFound = () => {
	return (
		<tr
			style={{
				width: '100%',
				position: 'relative',
				height: '100px',
			}}
			className="text-center"
		>
			<td>
				<div
					style={{
						display: 'block',
						textAlign: 'center',
						position: 'absolute',
						width: '95%',
						top: '30%',
					}}
					className="alert alert-light  "
					role="alert"
				>
					No Data Found
				</div>
			</td>
		</tr>
	);
};
export const NoItemFount = ({ children = 'No Data Found', ...props }) => {
	return (
		<div
			style={{
				display: 'block',
				textAlign: 'center',
				height: 'max-content',
			}}
			className="alert alert-light  "
			role="alert"
			{...props}
		>
			{children}
		</div>
	);
};

export const TableImage = ({ path, id, src }) => {
	return (
		<Link to={path + id}>
			<img
				style={{ height: '50px', objectFit: 'cover' }}
				className="tbl-thumb"
				src={src ? process.env.REACT_APP_IMG_URL + '/' + src : DemoIcon}
				alt="Product Images"
			/>
		</Link>
	);
};
export const TableImageNoUrl = ({ src, ...props }) => {
	return (
		<img
			style={{ height: '50px', objectFit: 'cover', borderRadius: '15px' }}
			className="tbl-thumb"
			src={src ? process.env.REACT_APP_IMG_URL + '/' + src : DemoIcon}
			alt="Product Images"
			{...props}
		/>
	);
};

export const TableSr = ({ page, i }) => {
	return page === null || parseInt(page) === 1
		? (i + 1).toString().padStart(2, '0')
		: (parseInt(page) - 1) * 10 + i + 1;
};

export const TableName = ({ id, text, countText, path }) => {
	return <Link to={path + id}>{countWord(text, countText)}</Link>;
};
export const TableLongText = ({ text, countText }) => {
	return <span>{countWord(text, countText)}</span>;
};

export const Role = ({ role }) => {
	switch (role) {
		case '2':
			return <span className="badge badge-info">Merchant</span>;
		case '3':
			return <span className="badge badge-success">Drop Shipper</span>;
		default:
			return <span className="badge badge-dark">User</span>;
	}
};

export const TableStatus = ({ status, text, suffix = '', ...props }) => {
	// 0 = pending, 1 = reject, 2 = active
	return (
		<span
			className={`mb-2 mr-2 badge ${
				((status === 'active' ||
					status === 'success' ||
					status === 'read' ||
					status === 'delivered' ||
					status === 'processing' ||
					status === 'ready' ||
					status === 'paid' ||
					status === 'received' ||
					status === '2') &&
					'badge-success') ||
				((status === 'rejected' ||
					status === '1' ||
					status === 'cancel' ||
					status === 'canceled' ||
					status === 'due' ||
					status === 'deactivate' ||
					status === 'deactive' ||
					status === 'blocked' ||
					status === 'reject') &&
					'badge-danger') ||
				((status === 'pending' ||
					status === 'ordered' ||
					status === 'unread' ||
					status === '0') &&
					'badge-warning') ||
				((status === 'answered' || status === 'hold') && 'badge-secondary') ||
				((status === 'progress' || status === 'revision') && 'badge-info') ||
				(status === 'return' && 'badge-dark')
			}`}
			{...props}
		>
			{text + suffix}
		</span>
	);
};

export const TableDiscount = ({ discount_rate, discount_type }) => {
	return (
		<span className={`badge badge-info`}>
			{discount_rate || 0}
			{discount_type === 'percent' ? '% ' : ' tk '}
		</span>
	);
};

export const TableQty = ({ qty }) => {
	return <span className="badge badge-dark">{qty}</span>;
};

export const TableTime = ({ date }) => {
	return (
		<span className="time">
			<span>{time(date).date || ''}</span>
			<span className="showTime">{time(date).time || ''}</span>
		</span>
	);
};

export const ClickToCopy = ({ text }) => {
	const { click_button_handler, copied } = CopyClipboardHandler();
	return (
		<button onClick={() => click_button_handler(text)}>
			{!copied ? <MdContentCopy /> : <RiCheckboxMultipleFill />}
		</button>
	);
};

export const ImageSelect = ({ src, onChange, multiple }) => {
	const style = {
		thumbUP: {
			margin: '2px 7px 10px 7px',
			position: 'relative',
			zIndex: 1,
			overflow: 'hidden',
			display: 'inline-block',
			width: 'auto',
			padding: 0,
		},
		thumbEdit: {
			position: 'absolute',
			top: '5px',
			right: '5px',
			zIndex: 1,
			cursor: 'pointer',
		},
		imgUpload: {
			opacity: 0,
			width: '30px',
			height: '30px',
			padding: '0',
			borderRadius: '50%',
			position: 'absolute',
			zIndex: 1,
			cursor: 'pointer',
		},
		label: {
			width: '30px',
			height: '30px',
			padding: '4px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			marginBottom: '0',
			borderRadius: '10px',
			background: '#ffffff',
			// border: '1px solid transparent',
			boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.15)',
			border: '1px solid #eeeeee',
			cursor: 'pointer',
			fontWeight: 'normal',
			transition: 'all 0.2s ease-in-out',
		},
		editIcon: {
			width: '18px',
			opacity: 0.6,
			cursor: 'pointer',
		},
		preview: {
			padding: '2px',
			position: 'relative',
			border: '1px solid #eeeeee',
			borderRadius: '10px',
		},
		previewThumb: {
			width: '80px',
			height: '80px',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		},
		imgView: {
			width: '100%',
			height: '100%',
			verticalAlign: 'middle',
			maxWidth: '100%',
			borderRadius: '7px',
		},
	};
	return (
		<div style={style.thumbUP}>
			<div style={style.thumbEdit}>
				<input
					type="file"
					id="thumbUpload01"
					multiple={multiple}
					name="image"
					onChange={onChange}
					style={style.imgUpload}
					accept=".png, .jpg, .jpeg"
				/>
				<label style={style.label} htmlFor="imageUpload">
					<img style={style.editIcon} src={img.edit} alt="edit" />
				</label>
			</div>
			<div style={style.preview}>
				<div style={style.previewThumb}>
					<img style={style.imgView} src={src} alt="edit" />
				</div>
			</div>
		</div>
	);
};
