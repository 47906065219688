import axios from 'axios';
import { alert, alertError, getCookie } from './actions';
import { LIVE_LINK } from '../env';
import { popUp } from './DeletePopUP';

export const http = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	headers: { 'X-Requested-With': 'XMLHttpRequest' },
	withCredentials: true,
});
http.interceptors.request.use(function (config) {
	const cK = getCookie('userInfo');
	if (cK === null) {
		const cb = () => (window.location.href = `${LIVE_LINK}/login`);
		return popUp('Logout', 'Please Login Again', 'success', cb);
	}
	// const token = localStorage.getItem('token');
	config.headers.Authorization = cK ? `Bearer ${cK.token}` : '';
	return config;
});

export const multipartConfig = {
	headers: {
		'content-type': 'multipart/form-data',
	},
};
