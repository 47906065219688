import logo from './logo.svg';

import dashboardIcon from './dashboard.svg';
import dashboardIconActive from './dashboard-active.svg';

import product from './product.svg';
import productActive from './product-active.svg';

import productOrder from './product-active.svg';
import productOrderActive from './product-active.svg';

import utility from './utility.svg';
import utilityActive from './utility.svg';

import addProduct from './addProduct.svg';
import addProductActive from './addProduct-active.svg';

import affiliateRequest from './affiliate-request.svg';
import affiliateRequestActive from './affiliate-request-active.svg';

import purchase from './purchase.svg';
import purchaseActive from './purchase-active.svg';

import sales from './sales.svg';
import salesActive from './sales-active.svg';

import returnIcon from './return.svg';
import returnIconActive from './return-active.svg';

import report from './report.svg';
import reportActive from './report-active.svg';

import coupons from './coupons.svg';
import couponsActive from './coupons-active.svg';

import membership from './membership.svg';
import membershipActive from './membership-active.svg';

import advertise from './advertise.svg';
import advertiseActive from './advertise-active.svg';

import serviceGig from './service-gig.svg';
import serviceGigActive from './service-gig-active.svg';

import serviceBuy from './service-buy.svg';
import serviceBuyActive from './service-buy-active.svg';

import wallet from './wallet.svg';
import walletActive from './wallet-active.svg';

import support from './support.svg';
import supportActive from './support-active.svg';

import cart from './cart.svg';
import cartActive from './cart-active.svg';

import income from './income.svg';
import incomeActive from './income-active.svg';

import allUser from './all-user.svg';
import allUserActive from './all-user-active.svg';

import vendor from './vendor.svg';
import vendorActive from './vendor-active.svg';

import user from './user.svg';
import userActive from './user-active.svg';

import manageCategories from './manage-categories.svg';
import manageCategoriesActive from './manage-categories-active.svg';

import brand from './brand.svg';
import brandActive from './brand-active.svg';

import vendorProduct from './vendor-product.svg';
import vendorProductActive from './vendor-product-active.svg';

import serviceTopic from './service-topic.svg';
import serviceTopicActive from './service-topic-active.svg';

import manageService from './manage-service.svg';
import manageServiceActive from './manage-service-active.svg';

import serviceOrder from './service-order.svg';
import serviceOrderActive from './service-order-active.svg';

import subscription from './subscription.svg';
import subscriptionActive from './subscription-active.svg';

import withdraw from './withdraw.svg';
import withdrawActive from './withdraw-active.svg';

import userRespose from './user-response.svg';
import userResposeActive from './user-response-active.svg';

import setting from './setting.svg';
import settingActive from './setting-active.svg';

import homeContent from './home-content.svg';
import homeContentActive from './home-content-active.svg';

import aboutContent from './about-content.svg';
import aboutContentActive from './about-content-active.svg';

import generalContent from './general-content.svg';
import generalContentActive from './general-content-active.svg';

import advertiseContent from './advertise-content.svg';
import advertiseContentActive from './advertise-content-active.svg';

import serviceContent from './service-content.svg';
import serviceContentActive from './service-content-active.svg';

import rolePermission from './role-permission.svg';
import rolePermissionActive from './role-permission-active.svg';

import subMenu from './sub-menu.svg';
import subMenuActive from './sub-menu-active.svg';

import puzzle from './puzzle.svg';
import puzzleActive from './puzzle-active.svg';

import list from './list.svg';
import listActive from './list-active.svg';

import setUp from './setup.svg';
import setUpActive from './setup-active.svg';

export const sidebarIcons = {
	logo,
	dashboard: [dashboardIcon, dashboardIconActive],
	product: [product, productActive],
	productOrder: [productOrder, productOrderActive],
	utility: [utility, utilityActive],
	addProduct: [addProduct, addProductActive],
	affiliateRequest: [affiliateRequest, affiliateRequestActive],
	purchase: [purchase, purchaseActive],
	sales: [sales, salesActive],
	returnIcon: [returnIcon, returnIconActive],
	report: [report, reportActive],
	coupons: [coupons, couponsActive],
	membership: [membership, membershipActive],
	advertise: [advertise, advertiseActive],
	serviceGig: [serviceGig, serviceGigActive],
	serviceBuy: [serviceBuy, serviceBuyActive],
	wallet: [wallet, walletActive],
	support: [support, supportActive],
	cart: [cart, cartActive],
	income: [income, incomeActive],
	allUser: [allUser, allUserActive],
	vendor: [vendor, vendorActive],
	user: [user, userActive],
	manageCategories: [manageCategories, manageCategoriesActive],
	brand: [brand, brandActive],
	vendorProduct: [vendorProduct, vendorProductActive],
	serviceTopic: [serviceTopic, serviceTopicActive],
	manageService: [manageService, manageServiceActive],
	order: [serviceOrder, serviceOrderActive],
	subscription: [subscription, subscriptionActive],
	withdraw: [withdraw, withdrawActive],
	userRespose: [userRespose, userResposeActive],
	setting: [setting, settingActive],
	homeContent: [homeContent, homeContentActive],
	aboutContent: [aboutContent, aboutContentActive],
	generalContent: [generalContent, generalContentActive],
	advertiseContent: [advertiseContent, advertiseContentActive],
	serviceContent: [serviceContent, serviceContentActive],
	rolePermission: [rolePermission, rolePermissionActive],
	subMenu: [subMenu, subMenuActive],
	puzzle: [puzzle, puzzleActive],
	list: [list, listActive],
	setUp: [setUp, setUpActive],
};
