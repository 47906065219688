import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { vendorMenu } from './sidebar-menu/vendorMenu';
import { handleSubMenubar, handlerMenubar } from '../action/actions';
import { apisVendor, useApi } from '../../api/common';
import { DashboardItem, MenuList, SOS, SubMenuList } from './SidebarItem';
import SidebarLoader from '../loader/SidebarLoader';
import { vendorRoutes } from '../../routes/all-routes';
import { useCountSupport } from './common/useCountSupport';

function VendorSidebar({ setSidebar }) {
	const [menuID, setMenuID] = useState(false);
	const { pathname } = useLocation();
	const { response, isLoading } = useApi({
		endPoint: apisVendor.employee.permissions,
	});

	const { sidebarMenu } = useCountSupport(vendorMenu, 'others');

	const checkPermission = (menu) => {
		// first check is employee
		if (response?.isEmployee === 'yes') {
			/* then check path 
				if path need to check role permission
				
			   # if not path that means sub route 
			   so filter out permission route and check length if getter then 0 show main menu
			   otherwise off main menu    
			*/
			if (
				menu?.path &&
				response?.permission?.vendor_role?.[menu?.permissionRoute] == 1
			) {
				return true;
			} else if (!menu?.path) {
				const res = menu?.subMenu?.filter(
					(e) => response?.permission?.vendor_role?.[e.permissionRoute] == 1
				);
				if (res.length > 0) {
					return true;
				}
				return false;
			}
			return false;
		}
		return true;
	};

	const subMenuPermission = (menu) => {
		/* check employee and then check value is the 	object of vendor_role
		 */
		if (response?.isEmployee === 'yes') {
			if (response?.permission?.vendor_role?.[menu] == 1) {
				return true;
			}
			return false;
		}

		return true;
	};

	return (
		<div className="ec-left-sidebar ec-bg-sidebar">
			<div id="sidebar" className="sidebar ec-sidebar-footer">
				<SOS setSidebar={setSidebar} />

				{/* <!-- begin sidebar scrollbar --> */}
				{isLoading && <SidebarLoader length={20} />}
				{!isLoading && (
					<div className="ec-navigation" data-simplebar>
						{/* <!-- sidebar menu --> */}
						<ul className="nav sidebar-inner" id="sidebar-menu">
							{/* <!-- Dashboard --> */}

							<DashboardItem
								active={pathname === vendorRoutes.dashboard}
								to={vendorRoutes.dashboard}
							/>
							{sidebarMenu?.map(
								(menu) =>
									checkPermission(menu) && (
										<li
											key={menu.id}
											onClick={(e) =>
												handlerMenubar(menu.id, menuID, setMenuID)
											}
											className={`has-sub ${
												menuID === menu.id &&
												menu.subMenu.length > 0 &&
												'expand'
											}`}
										>
											<MenuList menu={menu} pathname={pathname} />

											<div
												style={{
													display:
														menuID === menu.id &&
														menu.subMenu.length > 0 &&
														'block',
												}}
												className="collapse"
											>
												<ul
													className="sub-menu"
													id="categorys"
													data-parent="#sidebar-menu"
												>
													{menu.subMenu?.map(
														(subMenu, i) =>
															subMenuPermission(subMenu?.permissionRoute) && (
																<SubMenuList
																	key={i}
																	subMenu={subMenu}
																	handleSubMenubar={handleSubMenubar}
																	pathname={pathname}
																/>
															)
													)}
												</ul>
											</div>
											{menu.hr && <hr />}
										</li>
									)
							)}
						</ul>
					</div>
				)}
			</div>
		</div>
	);
}

export default VendorSidebar;
