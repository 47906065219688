export const affiliatesMenu = [
	{
		id: 2,
		name: 'Products',
		path: false,
		icon: 'product',
		arrow: true,
		hr: false,
		subMenu: [
			{
				name: 'Merchant Products',
				path: '/affiliates-dashboard/all-product',
			},
			{
				name: 'Active Products',
				path: '/affiliates-dashboard/active-product',
			},
			{
				name: 'Pending Products',
				path: '/affiliates-dashboard/pending-product',
			},
			{
				name: 'Rejected Product',
				path: '/affiliates-dashboard/rejected-product',
			},
			{
				name: 'Expired Product',
				path: '/affiliates-dashboard/expired-product',
			},
		],
	},

	{
		id: 4,
		name: 'Cart',
		path: '/affiliates-dashboard/add-to-cart',
		icon: 'cart',
		arrow: false,
		hr: false,
		subMenu: [],
	},
	{
		id: 3,
		name: 'Product Orders',
		path: false,
		icon: 'order',
		arrow: true,
		hr: true,
		subMenu: [
			{
				name: 'All Orders',
				path: '/affiliates-dashboard/orders-history-all',
			},
			{
				name: 'Hold Orders',
				path: '/affiliates-dashboard/orders-history-hold',
			},
			{
				name: 'Pending Orders',
				path: '/affiliates-dashboard/orders-history-pending',
			},
			{
				name: 'Received Orders',
				path: '/affiliates-dashboard/orders-history-received',
			},
			{
				name: 'Product Processing',
				path: '/affiliates-dashboard/orders-history-processing',
			},
			{
				name: 'Product Ready',
				path: '/affiliates-dashboard/orders-history-ready',
			},
			{
				name: 'Progress Orders',
				path: '/affiliates-dashboard/orders-history-progress',
			},
			{
				name: 'Delivered Orders',
				path: '/affiliates-dashboard/orders-history-delivered',
			},
			{
				name: 'Product Return',
				path: '/affiliates-dashboard/orders-history-return',
			},
			{
				name: 'Cancel Orders',
				path: '/affiliates-dashboard/orders-history-cancel',
			},
		],
	},

	{
		id: 7,
		name: 'Service & Orders',
		path: false,
		icon: 'serviceTopic',
		arrow: true,
		hr: true,
		subMenu: [
			{
				name: 'Create Service',
				path: '/affiliates-dashboard/create-service',
			},
			{
				name: 'All Service',
				path: '/affiliates-dashboard/all-service',
			},
			{
				name: 'Service Order',
				path: '/affiliates-dashboard/service-order',
			},
		],
	},

	{
		id: 8,
		name: 'My Coupon',
		path: '/affiliates-dashboard/coupon',
		icon: 'coupons',
		arrow: true,
		hr: false,
		subMenu: [],
	},
	{
		id: 9,
		name: 'MemberShip',
		path: '/affiliates-dashboard/renew',
		icon: 'membership',
		arrow: true,
		hr: true,
		subMenu: [],
	},
	{
		id: 10,
		name: 'Advertiser',
		path: '/affiliates-dashboard/advertise',
		icon: 'advertise',
		arrow: true,
		hr: true,
		subMenu: [],
	},
	{
		id: 11,
		name: 'Service Buy',
		path: '/affiliates-dashboard/my-service-order',
		icon: 'cart',
		arrow: true,
		hr: true,
		subMenu: [
			// {
			// 	name: 'All Order',
			// 	path: '/affiliates-dashboard/my-service-order',
			// },
			// {
			// 	name: 'Pending Order',
			// 	path: '/affiliates-dashboard/pending-order',
			// },
			// {
			// 	name: 'Progress Order',
			// 	path: '/affiliates-dashboard/progress-order',
			// },
			// {
			// 	name: 'Hold Order',
			// 	path: '/affiliates-dashboard/hold-order',
			// },
			// {
			// 	name: 'Cancel Order',
			// 	path: '/affiliates-dashboard/cancel-order',
			// },
		],
	},
	{
		id: 5,
		name: 'Income',
		path: false,
		icon: 'income',
		arrow: true,
		hr: false,
		subMenu: [
			{
				name: 'Available Income',
				path: '/affiliates-dashboard/available-balance',
			},
			{
				name: 'Pending Income',
				path: '/affiliates-dashboard/pending-balance',
			},
			{
				name: 'Balance History',
				path: '/affiliates-dashboard/balance-history',
			},
		],
	},

	{
		id: 12,
		name: 'Wallet',
		path: false,
		icon: 'wallet',
		arrow: true,
		hr: true,
		subMenu: [
			{
				name: 'Recharge',
				path: '/affiliate-dashboard/recharge',
			},
			{
				name: 'History',
				path: '/affiliates-dashboard/history',
			},
			{
				name: 'Withdraw Income',
				path: '/affiliates-dashboard/withdraw-balance',
			},
		],
	},
	{
		id: 13,
		name: 'Support',
		path: false,
		icon: 'support',
		arrow: true,
		hr: true,
		count: '0',
		subMenu: [
			{
				name: 'Create Support',
				path: '/affiliates-dashboard/create-support',
			},
			{
				name: 'All Support',
				path: '/affiliates-dashboard/all-support',
			},
		],
	},
	{
		id: 14,
		name: 'Chat',
		path: '/affiliates-dashboard/chat',
		icon: 'support',
		arrow: true,
		hr: true,
		subMenu: [],
	},
];
