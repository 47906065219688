import './sidebarLoader.css';

function SidebarLoader({ length = 1 }) {
	return Array(length)
		.fill(null)
		.map((_, i) => <Loader key={i} />);
}

export default SidebarLoader;
const Loader = () => {
	return (
		<div className="post" id="post-loader-abx-c9">
			<div className="avatar"></div>
			<div className="line"></div>
			<div className="line"></div>
		</div>
	);
};

export function LoaderBox({ length = 1 }) {
	return Array(length)
		.fill(null)
		.map((_, i) => (
			<div className="post fullWidth" id="post-loader-abx-c9">
				<div className="avatar"></div>
				<div className="line_group">
					<div className="line"></div>
					<div className="line"></div>
				</div>
			</div>
		));
}
