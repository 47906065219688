// [ note : never change title ]
export const rolePermissionData = [
	{
		title: 'Dashboard',
		routes: [{ name: 'Dashboard', path: 'dashboard' }],
	},
	{
		title: 'All User',
		routes: [{ name: 'All User', path: 'alluser' }],
	},
	{
		title: 'Merchant',
		routes: [
			{ name: 'Add Merchant', path: 'add-vendor' },
			{ name: 'All Merchant', path: 'all-vendor' },
			{ name: 'Active Merchant', path: 'active-vendor' },
			{ name: 'Pending Merchant', path: 'pending-vendor' },
		],
	},
	{
		title: 'Drop Shipper',
		routes: [
			{ name: 'Add Drop Shipper', path: 'add-affiliate' },
			{ name: 'All Drop Shipper', path: 'all-affiliate' },
			{ name: 'Active Drop Shipper', path: 'active-affiliate' },
			{ name: 'Pending Drop Shipper', path: 'pending-affiliate' },
		],
	},
	{
		title: 'User',
		routes: [
			{ name: 'Add User', path: 'add-user' },
			{ name: 'All User', path: 'all-user' },
			{ name: 'Active User', path: 'active-user' },
			{ name: 'Pending User', path: 'pending-user' },
		],
	},
	{
		title: 'Merchant Products',
		routes: [
			{ name: 'All Products', path: 'all-products' },
			{ name: 'Active Products', path: 'active-products' },
			{ name: 'Pending Products', path: 'pending-products' },
			{ name: 'Edit Products', path: 'edit-products' },
			{ name: 'Rejected Product', path: 'rejected-product' },
		],
	},
	{
		title: 'Drop Shipper Request',
		routes: [
			{ name: 'All Request', path: 'all-request' },
			{ name: 'Active Request', path: 'active-request' },
			{ name: 'Pending Request', path: 'pending-request' },
			{ name: 'Rejected Request', path: 'rejected-request' },
		],
	},
	{
		title: 'Manage Category',
		routes: [
			{ name: 'Category', path: 'category' },
			{ name: 'Sub Category', path: 'sub-category' },
		],
	},
	{
		title: 'Brand',
		routes: [{ name: 'Brand', path: 'brand' }],
	},

	{
		title: 'Manage Order',
		routes: [
			{ name: 'All Order', path: 'all-order' },
			{ name: 'Order Hold', path: 'order-hold' },
			{ name: 'Order Pending', path: 'order-pending' },
			{ name: 'Order Received', path: 'order-received' },
			{ name: 'Order Ready', path: 'order-ready' },
			{ name: 'Order Processing', path: 'order-processing' },
			{ name: 'Delivery Processing', path: 'delivery-processing' },
			{ name: 'Product Delivered', path: 'product-delivered' },
			{ name: 'Order Return', path: 'order-return' },
			{ name: 'Order Cancel', path: 'order-cancel' },
		],
	},
	{
		title: 'Withdraw',
		routes: [{ name: 'Withdraw', path: 'withdraw' }],
	},
	{
		title: 'Home Content',
		routes: [
			{ name: 'Home Service', path: 'home-service' },
			{ name: 'Organization', path: 'organization' },
			{ name: 'IT Service', path: 'it-service' },
			{ name: 'Organization Two', path: 'organization-two' },
			{ name: 'Partner', path: 'partner' },
			{ name: 'Content', path: 'content' },
			{ name: 'Home Update Content', path: 'home-update-content' },
		],
	},

	{
		title: 'About Content',
		routes: [
			{ name: 'Companions', path: 'companions' },
			{ name: 'Mission', path: 'mission' },
			{ name: 'Testimonial', path: 'testimonial' },
			{ name: 'Members', path: 'members' },
			{ name: 'About Update Content', path: 'abount-update-content' },
		],
	},
	{
		title: 'General Content',
		routes: [
			{ name: 'General Update Content', path: 'general-update-content' },
		],
	},
	{
		title: 'Advertise Content',
		routes: [
			{ name: 'FAQ', path: 'faq' },
			{ name: 'Advertise Update Content', path: 'advertise-update-content' },
		],
	},
	{
		title: 'Service Content',
		routes: [
			{ name: 'Service Update Content', path: 'service-update-content' },
		],
	},
	{
		title: 'Setting',
		routes: [{ name: 'Setting', path: 'setting' }],
	},
	{
		title: 'Service Topic',
		routes: [
			{ name: 'Service Category', path: 'service-category' },
			{ name: 'Service Sub Category', path: 'service-sub-category' },
		],
	},
	{
		title: 'Support',
		routes: [{ name: 'Support', path: 'support' }],
	},
	{
		title: 'Support Topic',
		routes: [
			{ name: 'Support Category', path: 'support-category' },
			{ name: 'Support Problem Topic', path: 'support-problem-topic' },
		],
	},
	{
		title: 'Advertiser',
		routes: [
			{ name: 'Advertise Utility', path: 'advertise-utility' },
			{ name: 'All Advertiser', path: 'all-advertiser' },
		],
	},
	{
		title: 'Subscription',
		routes: [{ name: 'Subscription', path: 'subscription' }],
	},
	{
		title: 'Users Response',
		routes: [{ name: 'Users Response', path: 'users-response' }],
	},
	{
		title: 'Coupon',
		routes: [
			{ name: 'Create Coupon', path: 'create-coupon' },
			{ name: 'Active Coupon', path: 'active-coupon' },
			{ name: 'Request Coupon', path: 'request-coupon' },
			{ name: 'Rejected Coupon', path: 'rejected-coupon' },
		],
	},
	{
		title: 'Manage Service',
		routes: [{ name: 'Manage Service', path: 'manage-service' }],
	},
	{
		title: 'Service Order',
		routes: [{ name: 'Service Order', path: 'service-order' }],
	},
	{
		title: 'Membership',
		routes: [{ name: 'Membership', path: 'membership' }],
	},
	{
		title: 'Role',
		routes: [{ name: 'Role', path: 'role' }],
	},
];
