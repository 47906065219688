import { NAME } from '../components/action';

const vDash = '/vendors-dashboard';
export const vendorRoutes = {
	dashboard: vDash,
	productAdd: `${vDash}/product/`,
	allProduct: `${vDash}/product-list/all`,
	activeProduct: `${vDash}/product-list/active`,
	pendingProduct: `${vDash}/product-list/pending`,
	editedProduct: `${vDash}/product-list/edited`,
	rejectedProduct: `${vDash}/product-list/rejected`,
	damageProduct: `${vDash}/product-list/damage-create`,
	damageProductList: `${vDash}/product-list/damage-list`,
	warehouse: `${vDash}/warehouse`,
	deliveryCharge: `${vDash}/delivery-charge`,
	unit: `${vDash}${NAME.unit.path}`,
	utility: `${vDash}${NAME.utility.path}`,
	variation: `${vDash}${NAME.variation.path}`,
	employee: `${vDash}/employee-list`,

	// orders
	orderAll: `${vDash}/orders-history-all`,
	orderNew: `${vDash}/new-order`,
	orderHold: `${vDash}/orders-history-hold`,
	orderPending: `${vDash}/orders-history-pending`,
	orderReceived: `${vDash}/orders-history-received`,
	orderProcessing: `${vDash}/orders-history-processing`,
	orderReady: `${vDash}/orders-history-ready`,
	orderProgress: `${vDash}/orders-history-progress`,
	orderDelivered: `${vDash}/orders-history-delivered`,
	orderReturn: `${vDash}/orders-history-return`,
	orderCancel: `${vDash}/orders-history-cancel`,

	// pos sales
	customer: `${vDash}/customer`,
	posSales: `${vDash}/pos-sales`,
	posManageSales: `${vDash}/manage-sales`,
	posSalesHistory: `${vDash}/sales-history`,

	// purchase
	supplier: `${vDash}/supplier`,
	paymentHistory: `${vDash}/payment-history`,
	purchase: `${vDash}/purchase`,
	managePurchase: `${vDash}/manage-purchase`,

	// barcode
	barcodeGenerate: `${vDash}/pos-sales-barcode-generator`,
	barcodeManage: `${vDash}/pos-sales-barcode-manage`,

	// setting
	orderSource: `${vDash}/order-source`,
	paymentMethods: `${vDash}/payment-methods`,
	invoiceGenerate: `${vDash}/invoice-generate`,
	deliveryCompany: `${vDash}/delivery-company`,

	// Affiliate request
	affiliateAllRequest: `${vDash}/affiliate-all-requested`,
	affiliateActiveRequest: `${vDash}/affiliate-active-requested`,
	affiliatePendingRequest: `${vDash}/affiliate-pending-requested`,
	affiliateRejectedRequest: `${vDash}/affiliate-rejected-requested`,
	affiliateExpiredRequest: `${vDash}/affiliate-expired-requested`,

	// Return List
	purchaseReturn: `${vDash}/return-list`,
	saleReturn: `${vDash}/sales-return`,
	addWastage: `${vDash}/add-wastage`,
	wastageList: `${vDash}/wastage/`,

	// report
	stokeReport: `${vDash}/stoke-report`,
	salesReport: `${vDash}/sales-report`,
	dueSalesReport: `${vDash}/due-sales-report`,
	purchaseReport: `${vDash}/purchase-report`,
	warehouseReport: `${vDash}/warehouse-report`,
	stockShortage: `${vDash}/stock-shortage-report`,
	topRepeatCustomer: `${vDash}/top-customer-report`,
	salesReportProductId: `${vDash}/sales-report-product-id`,
	salesReportDaily: `${vDash}/sales-report-daily-product-wise`,

	// service and order
	createService: `${vDash}/create-service`,
	allService: `${vDash}/all-service`,
	orderService: `${vDash}/service-order`,

	// coupon
	coupon: '/vendor/coupon',
	membership: `${vDash}/renew`,
	advertise: `${vDash}/advertise`,

	// service order
	myServiceOrder: `${vDash}/my-service-order`,

	// balance
	recharge: `${vDash}/recharge`,
	history: `${vDash}/history`,
	withdraw: `${vDash}/withdraw`,
	chat: `${vDash}/chat`,

	// support
	createSupport: `${vDash}/create-support`,
	allSupport: `${vDash}/all-support-ticket`,
	deliveryArea: `${vDash}/delivery-area`,
	pickupArea: `${vDash}/pickup-area`,
};
