import './sidebarLoader.css';

function SquareLoader({
	length = 1,
	flexWidth = '300px',
	maxHeight = '200px',
}) {
	return Array(length)
		.fill(null)
		.map((_, i) => (
			<Loader key={i} maxHeight={maxHeight} flexWidth={flexWidth} />
		));
}

export default SquareLoader;
const Loader = ({ flexWidth, maxHeight }) => {
	return (
		<div
			style={{ maxHeight: maxHeight, flex: `1 0 ${flexWidth}` }}
			className="post"
			id="post-loader-abx-c9-square"
		>
			<div className="avatar"></div>
		</div>
	);
};
