import { useEffect, useState } from 'react';
import { useApiAutoFetch } from '../../../api/common';

export const useCountSupport = (initialSidebarMenu, from = null) => {
	// Fetch the message count using your API hook
	const { response: messageCount } = useApiAutoFetch({
		endPoint: 'ticket-replay-count',
	});

	// State to store the updated sidebar menu
	const [sidebarMenu, setSidebarMenu] = useState(initialSidebarMenu);

	useEffect(() => {
		// Only update if messageCount exists
		if (messageCount) {
			// Check if we need to update the sidebarMenu to prevent unnecessary re-renders
			const updatedMenu = sidebarMenu.map((menu) => {
				if (menu.name === 'Support') {
					/*
					admin a new ticket + reply ticket
					others a new ticket + answer ticket
					*/
					const newCount =
						from === 'admin'
							? messageCount?.new_ticket_count +
							  messageCount?.reply_ticket_count
							: messageCount?.answer_ticket_count +
							  messageCount?.new_ticket_count;

					// Only update if count has changed
					if (menu.count !== newCount) {
						return {
							...menu,
							count: newCount,
						};
					}
				}
				return menu;
			});

			// Check if the menu actually changed before updating the state
			const hasChanged =
				JSON.stringify(sidebarMenu) !== JSON.stringify(updatedMenu);
			if (hasChanged) {
				setSidebarMenu(updatedMenu);
			}
		}
		// We remove sidebarMenu from the dependency array to avoid the loop
	}, [messageCount, from]);

	// Return the updated sidebarMenu
	return { sidebarMenu };
};
